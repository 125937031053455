import React, { memo, useEffect, useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateWidget } from '../../components';
import AdsWidget from '../ads';
import BackgroundGallery from '../backgroundGallery';
import Artist from '../backgroundGallery/artist.tsx';
import { getRandomBackground } from '../backgroundGallery/backgroundGalleryAPI';
import BookmarksFeature from '../bookmarks';
import { User } from '../index';
import NotificationsFeature from '../notification-center';
import { logoutUser, setUserTabs } from '../user/userAPI';
import {
    getSettings,
    selectToken,
    selectUser,
    selectUserSettings,
} from '../user/userSlice';
import BlockerMessage from './blocker-message';
import styles from './index.module.css';
import toTabLayout from './to-tab-layout';

const Newtab = () => {
    const dispatch = useDispatch();
    const settings = useSelector(selectUserSettings);
    const storeUser = useSelector(selectUser);
    const token = useSelector(selectToken);

    useLayoutEffect(() => {
        if (!token || token === '' || token === 'undefined') {
            dispatch(logoutUser());
        }

        if (
            localStorage.getItem('authuser') &&
            location.pathname.includes('newtab')
        ) {
            setTimeout(() => {
                dispatch(
                    setUserTabs({
                        username: storeUser.username,
                        id: storeUser.user_id,
                        token,
                    }),
                );
                dispatch(
                    getSettings({
                        token,
                    }),
                );
            }, 600);
        }
    }, []);

    useEffect(() => {
        dispatch(
            getRandomBackground({
                token,
            }),
        );
    }, []);

    const layoutStyle = toTabLayout(
        settings?.['general-settings']?.tab_layouts?.[0],
    );

    return (
        <div className={styles.wrapper}>
            <BlockerMessage />
            <BackgroundGallery />
            <NotificationsFeature />

            <div className={`${styles.widgets} ${layoutStyle}`}>
                <div className={`${styles.widget} ${styles.dateWidget}`}>
                    <DateWidget
                        settings={[
                            ...settings?.['general-settings']?.clock,
                            ...settings?.['general-settings']?.calendar,
                        ]}
                    />
                </div>
                <div className={`${styles.widget} ${styles.userWidget}`}>
                    <User />
                </div>

                <div className={`${styles.widget} ${styles.bookmarksWidget}`}>
                    <BookmarksFeature
                        setting={settings?.['general-settings']?.widgets?.[0]}
                    />
                </div>

                <div className={`${styles.widget} ${styles.artistWidget}`}>
                    <Artist />
                </div>

                <div className={`${styles.widget} ${styles.adsWidget}`}>
                    <AdsWidget />
                </div>
            </div>
        </div>
    );
};

export default memo(Newtab);
