import React from 'react';
import styles from './index.module.css';

export type Variant = 'primary' | 'warning' | 'danger' | 'default' | 'neutral';

export type AlertProps = React.PropsWithChildren & {
    variant?: Variant;
};

const Alert = ({ children, variant }: AlertProps) => {
    return (
        <stack-l
            class={styles.alert}
            space='var(--s-2)'
            data-variant={variant ?? 'primary'}
        >
            <div className={styles.body}>{children}</div>
        </stack-l>
    );
};

export default Alert;
