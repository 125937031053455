import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useDate from '../hooks/useDate';

const DateTime = ({ settings }) => {
    const [date, setDate] = useState(new Date());
    const [showSeconds, setShowSeconds] = useState(false);
    const [show24Hours, setShow24Hours] = useState(false);
    const [locale, setLocale] = useState('en-US');

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [settings]);

    useEffect(() => {
        const allSettings = {};

        settings.forEach((setting) => {
            allSettings[setting.key] = setting;
        });

        const showSeconds = allSettings?.show_clock_seconds;
        const show24Hours = allSettings?.show_clock_24_hours;
        const locale = allSettings?.calendar_locale;

        setShowSeconds(showSeconds?.value);
        setShow24Hours(show24Hours?.value);
        setLocale(locale?.value);
    }, [settings]);

    const min = date.getMinutes();
    const seconds = date.getSeconds();
    const hours = date.getHours();

    return (
        <DateCpWrapper>
            <DateCp>
                <div>
                    {show24Hours ? (
                        <DateWrapper>
                            {hours < 10 ? '0' + hours : hours} :{' '}
                            {min > 9 ? min : `0${min}`}{' '}
                            {showSeconds
                                ? `: ${seconds > 9 ? seconds : '0' + seconds}`
                                : ''}
                        </DateWrapper>
                    ) : (
                        <DateWrapper>
                            {(() => {
                                let locHours = hours > 12 ? hours - 12 : hours;

                                locHours =
                                    locHours < 10 ? '0' + locHours : locHours;

                                return (
                                    <>
                                        {locHours} : {min > 9 ? min : `0${min}`}{' '}
                                        {showSeconds
                                            ? `: ${
                                                  seconds > 9
                                                      ? seconds
                                                      : '0' + seconds
                                              }`
                                            : ''}{' '}
                                        {hours >= 12 ? 'PM' : 'AM'}
                                    </>
                                );
                            })()}
                        </DateWrapper>
                    )}
                </div>
                <div>
                    {(() => {
                        const { weekDay, month, day } = useDate(date, locale);
                        return (
                            <SpanWrapper>
                                {weekDay}, {month} {day}
                            </SpanWrapper>
                        );
                    })()}
                </div>
            </DateCp>
        </DateCpWrapper>
    );
};
DateTime.propTypes = {
    settings: PropTypes.array,
};

const SpanWrapper = styled.span`
    color: white;
    font-weight: bold;
    font-size: max(1.5rem, min(3.5vw, 3.5rem));
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
    text-transform: capitalize;
`;

const DateWrapper = styled.span`
    color: white;
    font-weight: bold;
    font-size: max(4rem, min(20vw, 10rem));
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4)); ;
`;

const DateCpWrapper = styled.div`
    z-index: 1;
    height: 0;
    width: 70vw;
    margin: 0px auto;
    display: grid;
    justify-items: center;
    align-content: center;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const DateCp = styled.div`
    width: 100%;
    height: 100%;
`;

export default DateTime;
