import React from 'react';

import './amazon-ad-widget.scss';

function AmazonAdWidget() {
    return (
        <div className='amazon-ad-widget'>
            <iframe
                src='//rcm-na.amazon-adsystem.com/e/cm?o=1&p=290&l=ur1&category=health&banner=1HAR195G76KEBVJB48R2&f=ifr&linkID=5f1a0ed05ee6544e60e24720ebc9f4c9&t=hyetab-20&tracking_id=hyetab-20'
                width='320'
                height='250'
                scrolling='no'
                border='0'
                marginWidth='0'
                style={{ border: 'none' }}
                frameBorder='0'
            />
        </div>
    );
}

export default AmazonAdWidget;
