/* eslint-disable indent */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import {
    getNotifications,
    getNotificationsCount,
    markAllAsRead,
    markAsRead,
} from './effects';

export enum Status {
    PENDING = 'PENDING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
}

export interface ErrorDto {
    code?: string | null;
    message?: string | null;
    errors?: ErrorDto[] | null;
}

export enum Type {
    NotificationItem = 'notification-item',
}

export type Notification = {
    type: Type;
    title: string;
    title_tag: string | null;
    slug: string;
    body: string;
};

export type NotificationDetails = {
    id: number;
    creation_date: string;
    read_by_user: boolean;
    item: Notification;
};

export interface NotificationsState {
    count: number;
    list: NotificationDetails[] | null;
    status: Status | null;
    error: ErrorDto | null;
}

const initialState: NotificationsState = {
    count: 0,
    list: null,
    status: null,
    error: null,
};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: { ...initialState },
    reducers: {},

    extraReducers: (builder) => {
        builder
            .addCase(
                getNotificationsCount.fulfilled,
                (state, action: PayloadAction<{ count: number }>) => {
                    state.count = action.payload.count;
                },
            )
            .addCase(getNotifications.pending, (state) => {
                state.status = Status.PENDING;
            })
            .addCase(getNotifications.fulfilled, (state, action) => {
                state.list = action.payload;
                state.status = Status.SUCCESS;
            })

            .addCase(
                markAsRead.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        data: {
                            notification_id: number;
                            read_by_user: boolean;
                        };
                    }>,
                ) => {
                    const list = state.list?.length
                        ? state.list.map((n) => {
                              if (
                                  n.id === action.payload.data.notification_id
                              ) {
                                  n.read_by_user =
                                      action.payload.data.read_by_user;
                              }
                              return n;
                          })
                        : [];

                    state.list = list;
                    state.count = list.filter(
                        (n) => n.read_by_user === false,
                    ).length;
                },
            )

            .addCase(markAllAsRead.fulfilled, (state) => {
                const list = state.list?.length
                    ? state.list.map((n) => {
                          n.read_by_user = true;
                          return n;
                      })
                    : [];

                state.list = list;
                state.count = 0;
            });
    },
});

// export const { markAsRead } = notificationsSlice.actions;
export const selectNotificationsCount = (state: {
    notifications: NotificationsState;
}) => state.notifications.count;

export const selectNotifications = (state: {
    notifications: NotificationsState;
}) => state.notifications.list;

export const selectStatus = (state: { notifications: NotificationsState }) =>
    state.notifications.status;

export default notificationsSlice.reducer;
