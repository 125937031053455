import React, { useState, useEffect } from 'react';

import { Formik, Form } from 'formik';

import CustomInput from '../CustomInput';

import {
    AuthSection,
    AuthWrapper,
    AuthTitle,
    AuthForm,
    AuthInput,
    AuthInputType,
    AuthInputLabel,
    AuthErrorLabel,
    AuthResponseStatus,
    AuthBtnWrapper,
    RegisterBtn,
    AuthText,
    AuthHighlighted,
    SocialHighlighted,
} from './AccountAuthFormStyles';

import AUTH_METHODS from './AuthenticationMethods';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const AccountAuthForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [authMethod, setAuthMethod] = useState(
        AUTH_METHODS[location.pathname.split('/')[2].toUpperCase()],
    );

    useEffect(() => {
        setAuthMethod(
            AUTH_METHODS[location.pathname.split('/')[2].toUpperCase()],
        );
    }, [location]);

    return (
        <AuthSection>
            <AuthTitle>{authMethod.title}</AuthTitle>
            <AuthWrapper>
                <Formik
                    initialValues={{
                        user__name: '',
                        user__email: '',
                        user__password: '',
                    }}
                    validationSchema={authMethod.validation}
                    validateOnChange
                    onSubmit={(e, { setStatus }) => {
                        function successHandler() {
                            history.push('/newtab');
                        }

                        function errorHandler(err) {
                            setStatus({ message: err.response });
                        }

                        authMethod.authSubmit(
                            e,
                            dispatch,
                            successHandler,
                            errorHandler,
                        );
                    }}
                >
                    {({ values, submitForm, status }) => (
                        <Form>
                            <AuthForm>
                                {authMethod &&
                                authMethod.fields &&
                                authMethod.fields.length
                                    ? authMethod.fields.map((auth, key) => (
                                          <CustomInput
                                              key={`${auth.id}-${key}`}
                                              name={auth.id}
                                              id={auth.id}
                                              label={auth.label}
                                              placeholder={auth.placeholder}
                                              value={values[auth.value]}
                                              type={
                                                  auth.type !== undefined
                                                      ? auth.type
                                                      : ''
                                              }
                                              inputElement={AuthInputType}
                                              inputLabelWrapper={AuthInputLabel}
                                              inputWrapper={AuthInput}
                                              errorLabel={AuthErrorLabel}
                                              autoComplete='on'
                                          />
                                      ))
                                    : ''}

                                {status && status.message && (
                                    <AuthResponseStatus>
                                        {status.message}
                                    </AuthResponseStatus>
                                )}

                                <AuthBtnWrapper>
                                    <RegisterBtn
                                        onClick={(e) => {
                                            e.preventDefault();
                                            submitForm();
                                        }}
                                    >
                                        {authMethod.authBtn}
                                    </RegisterBtn>

                                    <AuthText>
                                        {authMethod.authText}{' '}
                                        <AuthHighlighted>
                                            <Link
                                                to={`/account/${authMethod.authCtaMethod?.toLowerCase()}`}
                                            >
                                                {authMethod.authCtaBtn}
                                            </Link>
                                        </AuthHighlighted>
                                        <SocialHighlighted>
                                            or use{' '}
                                            <Link to='/account/social'>
                                                Your Social Account
                                            </Link>{' '}
                                            to Sign Up
                                        </SocialHighlighted>
                                    </AuthText>
                                </AuthBtnWrapper>
                            </AuthForm>
                        </Form>
                    )}
                </Formik>
            </AuthWrapper>
        </AuthSection>
    );
};

export default AccountAuthForm;
