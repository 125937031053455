import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute({
    component,
    condition,
    redirectRoute = '/account/social',
    absoluteUrl = false,
    ...rest
}) {
    if (absoluteUrl) {
        const url = new URL(redirectRoute);
        window.location.assign(url);

        return null;
    }

    return (
        <Route
            {...rest}
            render={(props) => (
                <RenderedComponent
                    absoluteUrl={absoluteUrl}
                    component={component}
                    condition={condition}
                    redirectRoute={redirectRoute}
                    {...props}
                />
            )}
        />
    );
}

function RenderedComponent({
    component: Component,
    condition,
    redirectRoute,
    location,
    ...props
}) {
    return condition ? (
        <Component {...props} />
    ) : (
        <Redirect to={{ pathname: redirectRoute, state: { from: location } }} />
    );
}

PrivateRoute.propTypes = {
    component: PropTypes.elementType | undefined,
    condition: PropTypes.bool,
    redirectRoute: PropTypes.string,
    absoluteUrl: PropTypes.bool,
};

RenderedComponent.propTypes = {
    component: PropTypes.elementType,
    condition: PropTypes.bool,
    redirectRoute: PropTypes.string,
    location: PropTypes.object,
};

export { PrivateRoute };
