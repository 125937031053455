import React from 'react';
import styles from './index.module.css';

export type TooltipProps = React.PropsWithChildren<{
    content?: string | React.ReactElement | React.ReactNode;
    onClick?: () => void;
    badge?: React.ReactNode;
}>;

const Tooltip = ({ content, children, badge, ...props }: TooltipProps) => {
    return (
        <stack-l class={styles.tooltip} onClick={props?.onClick}>
            <div className={styles.body}>{children}</div>
            <span className={styles.badge}>{badge}</span>
            {content && <div className={styles.content}>{content}</div>}
        </stack-l>
    );
};

export default Tooltip;
