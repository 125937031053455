import styles from './index.module.css';

export enum Layouts {
    Bookmark = 'bookmark-theme',
    Date = 'date-&-time-theme"',
    Stacked = 'stacked-theme',
}

const toTabLayout = (setting: { value: string; key: string }) => {
    let layout = '';

    switch (setting?.value) {
        case Layouts.Bookmark:
            layout = styles.centeredBookmarks;
            break;
        case Layouts.Stacked:
            layout = styles.stacked;
            break;
        default:
            layout = styles.centeredDate;
            break;
    }

    return layout;
};

export default toTabLayout;
