export function keyify(el?: string) {
    const matchedSpaces = el?.split(' ');

    if (matchedSpaces && matchedSpaces.length) {
        return matchedSpaces?.join('_')?.toLowerCase();
    }

    return el;
}

export function makeLabel(el?: string) {
    const matchedCamelCase = el?.match(/[A-Z][a-z]+/g);
    const matchedDashes = el?.split('-');
    const matchedUnderscores = el?.split('_');

    if (matchedCamelCase && matchedCamelCase.length) {
        return matchedCamelCase?.join(' ');
    }

    if (matchedDashes && matchedDashes.length > 1) {
        return matchedDashes?.join(' ');
    }

    if (matchedUnderscores && matchedUnderscores?.length > 1) {
        return matchedUnderscores?.join(' ');
    }

    return el;
}
