import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import './settings-tab.scss';
import Logo from '../Logo';

const SettingsTabs = () => {
    const location = useLocation();

    const tabs = [
        {
            tab: 'general-settings',
            label: 'General Settings',
        },
        {
            tab: 'profile',
            label: 'Your Profile',
        },
        {
            tab: 'rewards',
            label: 'Use Upvotes',
        },
        // {
        //   tab: 'invite',
        //   label: 'Invite',
        // },
    ];

    return (
        <div className='settings-tabs'>
            <ul className='navigation'>
                {tabs.map((item) => {
                    return (
                        <li
                            key={item.tab}
                            className={`navigation-tab ${
                                location.pathname.includes(item.tab)
                                    ? 'active'
                                    : ''
                            }`}
                        >
                            <Link key={item.tab} to={`/settings/${item.tab}`}>
                                {item.label}
                            </Link>
                        </li>
                    );
                })}
            </ul>

            <Link to='/newtab' className='settings-logo'>
                <Logo />
            </Link>
        </div>
    );
};

export default memo(SettingsTabs);
