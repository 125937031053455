import React, { useLayoutEffect } from 'react';
import {
    HiDotsVertical,
    IoLogOutOutline,
    IoMdHelpCircleOutline,
    IoSettingsOutline,
    IoTriangle,
} from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Tooltip } from '../../components';
import NotificationsTrigger from '../notification-center/trigger';
import logo from './logo.png';
import './user.scss';
import { getMoney, logoutUser } from './userAPI';
import { selectMoney, selectTabsCounter, selectToken } from './userSlice';

const User = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const tabsCounter = useSelector(selectTabsCounter);
    const generatedMoney = useSelector(selectMoney);
    const token = useSelector(selectToken);

    useLayoutEffect(() => {
        dispatch(
            getMoney({
                token,
            }),
        );
    }, []);

    const money = Number(generatedMoney)?.toPrecision()?.toString();

    return (
        <div className='user'>
            <Tooltip content='Total generated revenue by HyeTab'>
                <div className='money-counter'>$ {money && money}</div>
            </Tooltip>
            <Tooltip content='Earn an Upvote with every new tab'>
                <Link to='/settings/rewards' className='tabs-counter'>
                    <span className='icon'>
                        <IoTriangle />
                    </span>
                    {tabsCounter}
                </Link>
            </Tooltip>

            <div className='notifications'>
                <NotificationsTrigger />
            </div>

            <a
                href='https://hyetab.io/'
                target='_blank'
                className='logo'
                rel='noreferrer'
            >
                <img src={logo} />
            </a>

            <div className='user__name'>
                <HiDotsVertical />
                <div className='user__dropdown'>
                    <a
                        className='settings-btn action-btn'
                        href='https://hyetab.io/faq'
                        rel='noreferrer'
                        target='_blank'
                    >
                        <IoMdHelpCircleOutline />
                        Help
                    </a>
                    <Link
                        className='settings-btn action-btn'
                        to='/settings/general-settings'
                    >
                        <IoSettingsOutline />
                        Settings
                    </Link>
                    <button
                        className='logout-btn action-btn'
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(logoutUser())
                                .then(() => {
                                    history.push('/account/social');
                                })
                                .catch((err) => {
                                    console.error('logout error ', err);
                                });
                        }}
                    >
                        <IoLogOutOutline />
                        Log Out
                    </button>
                </div>
            </div>
        </div>
    );
};

export default User;
