import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { formatDate } from '../../../utils/date';
import {
    selectToken,
    selectUserId,
    selectUserSettings,
} from '../../user/userSlice';
import { markAsRead } from '../_store/effects';
import { NotificationDetails } from '../_store/state';
import styles from './index.module.css';

type Settings = {
    'general-settings': {
        calendar: { key: string; value: string }[];
    };
};

const NotificationItem: FC<{ data: NotificationDetails }> = ({ data }) => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const userId = useSelector(selectUserId);
    const settings: Settings = useSelector(selectUserSettings);
    const locale = settings['general-settings'].calendar[0].value;

    const date = formatDate(data.creation_date, locale, {
        dateStyle: 'medium',
    });
    const sanitizedBody = sanitizeHtml(data.item.body);

    const handleClick = useCallback(() => {
        dispatch(
            markAsRead({
                token,
                userId,
                notificationId: data.id,
            }),
        );
    }, [dispatch]);

    return (
        <cluster-l
            space='var(--s1)'
            class={styles.wrapper}
            onClick={handleClick}
        >
            <stack-l class={styles.card}>
                <stack-l space='var(--s-4)'>
                    <span className={styles.date}>{date}</span>
                    <h2 className={styles.title}>{data.item.title}</h2>
                </stack-l>

                <div
                    className={styles.body}
                    dangerouslySetInnerHTML={{ __html: sanitizedBody }}
                />
            </stack-l>
            <span
                className={`${styles.badge} ${
                    !data.read_by_user ? styles.show : ''
                }`}
            />
        </cluster-l>
    );
};

export default NotificationItem;
