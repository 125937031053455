import React from 'react';
import PropTypes from 'prop-types';

import './statistic-block.scss';

function StatisticBlock({ setting }) {
    const { label, value, value_type, postfix } =
        setting !== undefined && setting !== null && setting;

    function datediff(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    return (
        <div className='setting-block'>
            <h2 className='title'>{label}</h2>
            <p className='value'>
                {value_type === 'date' ? (
                    <>
                        {datediff(new Date(value), new Date())}
                        <span>{postfix}</span>
                    </>
                ) : (
                    <>
                        {value}
                        <span>{postfix}</span>
                    </>
                )}
            </p>
        </div>
    );
}

StatisticBlock.propTypes = {
    setting: PropTypes.object,
};

export default StatisticBlock;
