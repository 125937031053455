import React, { useState } from 'react';
import { Blurhash } from 'react-blurhash';
import { useSelector } from 'react-redux';
import { selectBackgroundItem } from './backgroundGallerySlice';
import styles from './index.module.css';

const BackgroundItem = () => {
    const [active, setActive] = useState(false);
    const backgroundItem = useSelector(selectBackgroundItem);

    return (
        <div className={`${styles.background} `}>
            {backgroundItem.img_hash ? (
                <Blurhash
                    hash={backgroundItem.img_hash}
                    width='100vw'
                    height='100vh'
                    punch={1}
                />
            ) : null}
            <img
                onLoad={() => {
                    setActive(true);
                }}
                loading='lazy'
                className={active ? styles.active : ''}
                src={`${backgroundItem ? backgroundItem.img : ''}`}
            />
        </div>
    );
};

export default BackgroundItem;
