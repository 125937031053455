import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../shared';

const getRandomBackground = createAsyncThunk(
    'user/getRandomBackground',
    async ({ token }) => {
        const res = await ApiCall('gallery/random/', {
            method: 'get',
            csrf: true,
            token,
        });

        return res.data;
    },
);

export { getRandomBackground };
