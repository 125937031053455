import { createSlice } from '@reduxjs/toolkit';
import { getBookmarks, setBookmarks } from './bookmarksAPI';

let storageBookmarks = [];
try {
    storageBookmarks = JSON.parse(localStorage?.getItem('storageWebsites'));
} catch (err) {}

export const bookmarkSlice = createSlice({
    name: 'bookmark',
    initialState: {
        bookmarks: storageBookmarks,
    },
    reducers: {},

    extraReducers: {
        [getBookmarks.fulfilled]: (state, { payload }) => {
            state.bookmarks = payload.bookmarks;
            localStorage.setItem('storageWebsites', payload.bookmarks);
        },

        [setBookmarks.fulfilled]: (state, { payload }) => {
            state.bookmarks = payload.bookmarks;
            localStorage.setItem(
                'storageWebsites',
                JSON.stringify(payload.bookmarks),
            );
        },
    },
});

export const selectBookmarks = (state) => state.bookmark;

export default bookmarkSlice.reducer;
