import { Bookmarker } from '../../components';
import {
    getBookmarks,
    setBookmarks,
} from '../../features/bookmarks/bookmarksAPI';
import { selectToken, selectUser } from '../../features/user/userSlice';
import { useSelector } from 'react-redux';
import Add from './add';
import BookmarksList from './list';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import SearchWidget from './search/index.tsx';
import styles from './index.module.css';
import useAppDispatch from '../../app/dispatcher';

const BookmarksFeature = ({ setting }) => {
    const [bookmarksStore, setBookmarksStore] = useState([]);
    const [showBookmarker, setShowBookmarker] = useState(false);
    const [bookmarksShown, setBookmarksShown] = useState(setting?.value);

    const dispatch = useAppDispatch();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    useEffect(() => {
        let sites = [];
        localStorage.removeItem('storageWebsites');

        dispatch(getBookmarks({ id: user.user_id, token })).then(() => {
            if (localStorage) {
                const storage = localStorage.getItem('storageWebsites')
                    ? JSON.parse(localStorage.getItem('storageWebsites'))
                    : [];
                sites = storage;
            }
            sites?.length && setBookmarksStore(sites);
        });
    }, [showBookmarker]);

    useEffect(() => {
        setBookmarksShown(setting.value);
    }, [setting]);

    function setBookmark(bookmark) {
        const bookmarks = JSON.stringify([...bookmarksStore, bookmark]);

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    function handleDelete(deleteId) {
        const bookmarks = JSON.stringify(
            bookmarksStore?.filter((item) => {
                return item.id !== deleteId;
            }),
        );

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    const handleAdd = useCallback(() => {
        setShowBookmarker(!showBookmarker);
    }, [setShowBookmarker]);

    return bookmarksShown ? (
        <stack-l space='var(--s0)'>
            <SearchWidget />
            <Bookmarker
                show={showBookmarker}
                toggleBookmarker={setShowBookmarker}
                setBookmark={setBookmark}
            />
            <div className={styles.wrapper}>
                <div className={styles.background} />
                <cluster-l
                    space='var(--s-6)'
                    align='center'
                    justify='flex-start'
                >
                    <BookmarksList
                        bookmarks={bookmarksStore}
                        handleDelete={handleDelete}
                    />
                    <Add
                        bookmarksLength={bookmarksStore?.length}
                        handleClick={handleAdd}
                    />
                </cluster-l>
            </div>
        </stack-l>
    ) : (
        <SearchWidget />
    );
};

BookmarksFeature.propTypes = {
    setting: PropTypes.array,
};

export default BookmarksFeature;
