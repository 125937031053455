import { Alert } from '../../../components';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import React from 'react';
import styles from './index.module.css';

const Comment = () => {
    return (
        <center-l>
            <cluster-l justify='center'>
                <Alert>
                    <cluster-l
                        justify='flex-start'
                        space='var(--s-2)'
                        class={styles.wrapper}
                        align='center'
                    >
                        <icon-l space='var(--s-5)' class={styles.icon}>
                            <BsFillInfoCircleFill />
                        </icon-l>
                        When you use your Upvotes, you’re telling us to give
                        more of the money we raised to that charity
                    </cluster-l>
                </Alert>
            </cluster-l>
        </center-l>
    );
};

export default Comment;
