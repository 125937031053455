import React from 'react';
import PropTypes from 'prop-types';

import './setting-input.scss';

function SettingInput({ setting }) {
    const { value, key } = setting !== undefined && setting !== null && setting;
    return (
        <div className='setting-input'>
            <h2 className='title'>{key.replace('_', ' ')}</h2>
            <p className='value'>{value}</p>
        </div>
    );
}

SettingInput.propTypes = {
    setting: PropTypes.object,
};

export default SettingInput;
