import { configureStore } from '@reduxjs/toolkit';
import {
    backgroundReducer,
    bookmarksReducer,
    notificationsReducers,
    userReducer,
} from '../features';

const store = configureStore({
    reducer: {
        user: userReducer,
        background: backgroundReducer,
        bookmarks: bookmarksReducer,
        notifications: notificationsReducers,
    },
});

export default store;
