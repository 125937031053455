import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactGoogle from 'react-google-login';
import { GOOGLE_CLIENT_ID } from '../../settings';

import { MdEmail } from 'react-icons/md';
import Logo from '../Logo';

import { useDispatch } from 'react-redux';
import { googleLogin } from '../../features/user/userAPI';

import './social-signup.scss';

const SocialSignup = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const handleSuccess = (data) => {
        (async () => {
            const { profileObj } = data;
            dispatch(googleLogin(profileObj));
            history.push('/newtab');
        })();
    };

    return (
        <div className='social-section'>
            <Logo />
            <div className='social__btns'>
                <ReactGoogle
                    clientId={GOOGLE_CLIENT_ID}
                    buttonText='Login with Google'
                    className='signup-btn'
                    onSuccess={handleSuccess}
                />
                <Link to='/account/login'>
                    <button className='email-login-btn'>
                        <MdEmail /> Login In with Email
                    </button>
                </Link>
            </div>
            <div className='account-login__cta'>
                By continuing, you are indicating that you accept our <br />
                <Link to='/terms'>Terms of Service</Link> and{' '}
                <Link to='/privacy'>Privacy Policy</Link>
            </div>
        </div>
    );
};

export default SocialSignup;
