import { useEffect } from 'react';

const useAdblockDetector = (handleMessageTrigger: (state: boolean) => void) => {
    useEffect(() => {
        if (window) {
            setTimeout(() => {
                const testElement = window.document.getElementById(
                    'test-blockers',
                );

                const firstCase =
                    testElement &&
                    window.getComputedStyle(testElement).display === 'none';

                if (firstCase) {
                    handleMessageTrigger(firstCase);
                }
            }, 100);
        }
    });

    return null;
};

export default useAdblockDetector;
