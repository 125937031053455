import { Alert } from '../../components';
import { IoMdWarning } from 'react-icons/io';
import { NEWTAB_MESSAGES } from './messages';
import React, { useCallback, useState } from 'react';
import styles from './index.module.css';
import useAdblockDetector from './use-adblock-detector';

const BlockerMessage = () => {
    const [blockersEnabled, setBlockersEnabled] = useState(false);

    const handleMessageTrigger = useCallback((state: boolean) => {
        setBlockersEnabled(state);
    }, []);

    useAdblockDetector(handleMessageTrigger);

    return (
        <cluster-l
            justify='center'
            class={`${styles.blockersDetected} ${
                blockersEnabled && styles.showWarning
            }`}
        >
            <Alert variant='warning'>
                <cluster-l
                    justify='flex-start'
                    space='var(--s-2)'
                    align='center'
                >
                    <icon-l space='var(--s-5)' class={styles.icon}>
                        <IoMdWarning />
                    </icon-l>

                    <stack-l>
                        {NEWTAB_MESSAGES.AD_BLOCKER_DETECTED?.split('\n').map(
                            (ms) => (
                                <>
                                    {ms}
                                    <br />
                                </>
                            ),
                        )}
                    </stack-l>
                </cluster-l>
            </Alert>
        </cluster-l>
    );
};

export default BlockerMessage;
