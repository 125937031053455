import { CustomField } from '../../../components';
import { GoSearch } from 'react-icons/go';
import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import styles from './index.module.css';

function SearchWidget() {
    const [searchEngine] = useState({
        engine: 'google',
        name: 'Google',
        icon: <GoSearch />,
    });

    return (
        <div className={styles.search}>
            <Formik
                initialValues={{
                    search_value: '',
                }}
                validateOnChange
                onSubmit={(e, { resetForm }) => {
                    resetForm();
                    window.location.replace(
                        `https://www.${searchEngine.engine}.com/search?q=${e.search_value}`,
                    );
                }}
            >
                {({ values, submitForm }) => (
                    <Form className='search-form'>
                        <CustomField
                            name='search_value'
                            id='search_value'
                            fieldClass={styles.field}
                            fieldLabelClass={styles.label}
                            fieldErrorLabelClass={styles.error}
                            fieldInputClass={styles.input}
                            fieldLabel={() => searchEngine.icon}
                            searchAction={submitForm}
                            placeholder={`Search with ${searchEngine.name}`}
                            value={values.search_value}
                            autoFocus
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default SearchWidget;
