import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useDate from '../../hooks/useDate';

import './date-widget.scss';

function DateWidget({ settings }) {
    const [date, setDate] = useState(new Date());
    const [showSeconds, setShowSeconds] = useState(false);
    const [show24Hours, setShow24Hours] = useState(false);
    const [locale, setLocale] = useState('en-US');

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(new Date());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [settings]);

    useEffect(() => {
        const allSettings = {};

        settings.forEach((setting) => {
            allSettings[setting.key] = setting;
        });

        const showSeconds = allSettings?.show_clock_seconds;
        const show24Hours = allSettings?.show_clock_24_hours;
        const locale = allSettings?.calendar_locale;

        setShowSeconds(showSeconds?.value);
        setShow24Hours(show24Hours?.value);
        setLocale(locale?.value);
    }, [settings]);

    const min = date.getMinutes();
    const seconds = date.getSeconds();
    const hours = date.getHours();

    return (
        <div className='date-widget'>
            {show24Hours ? (
                <div className='widget-time-wrapper'>
                    {hours < 10 ? '0' + hours : hours}:
                    {min > 9 ? min : `0${min}`}
                    {showSeconds && ':'}
                    {showSeconds
                        ? `${seconds > 9 ? seconds : '0' + seconds}`
                        : ''}
                </div>
            ) : (
                <div className='widget-time-wrapper'>
                    {(() => {
                        let locHours =
                            hours > 12
                                ? hours - 12
                                : hours - 12 === -12
                                ? 12
                                : hours;

                        locHours = locHours < 10 ? '0' + locHours : locHours;

                        return (
                            <>
                                {locHours}:{min > 9 ? min : `0${min}`}
                                {showSeconds && ':'}
                                {showSeconds
                                    ? `${seconds > 9 ? seconds : '0' + seconds}`
                                    : ''}{' '}
                                {hours >= 12 ? 'PM' : 'AM'}
                            </>
                        );
                    })()}
                </div>
            )}
            <div className='widget-date-wrapper'>
                {(() => {
                    const { weekDay, month, day } = useDate(date, locale);
                    return (
                        <cluster-l className='dates' space='var(--s-2)'>
                            <cluster-l space='var(--s-2)' justify='center'>
                                {month} {day},
                            </cluster-l>
                            {weekDay}
                        </cluster-l>
                    );
                })()}
            </div>
        </div>
    );
}

DateWidget.propTypes = {
    settings: PropTypes.array,
};

export default DateWidget;
