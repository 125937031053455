import * as Validation from './AccountAuthFormValidation';
import { loginUser, registerUser } from '../../features/user/userAPI';

const AUTH_METHODS = {
    LOGIN: {
        title: 'Sign In',
        fields: [
            {
                id: 'user__email',
                placeholder: 'Enter your email',
                label: 'Email',
                value: 'email',
                type: 'email',
            },
            {
                id: 'user__password',
                placeholder: 'Enter your password',
                label: 'Password',
                value: 'password',
                type: 'password',
            },
        ],
        validation: Validation.LoginFormValidation,
        authBtn: 'Sign In',
        authCtaMethod: 'REGISTER',
        authCtaBtn: 'Sign Up',
        authText: "Don't have an account?",
        authSubmit: (e, dispatch, successHandler, errorHandler) => {
            dispatch(loginUser(e))
                .then(() => {
                    return successHandler();
                })
                .catch((err) => errorHandler(err));
        },
    },
    REGISTER: {
        title: 'Sign Up',
        fields: [
            {
                id: 'user__name',
                placeholder: 'Enter your username',
                label: 'Username',
                value: 'username',
            },
            {
                id: 'user__email',
                placeholder: 'Enter your email',
                label: 'Email',
                value: 'email',
                type: 'email',
            },
            {
                id: 'user__password',
                placeholder: 'Enter your password',
                label: 'Password',
                value: 'password',
                type: 'password',
            },
        ],
        validation: Validation.RegisterFormValidation,
        authBtn: 'Sign Up',
        authCtaMethod: 'LOGIN',
        authCtaBtn: 'Sign In',
        authText: 'Already have an account?',
        authSubmit: (e, dispatch, successHandler, errorHandler) => {
            dispatch(registerUser(e))
                .then(() => {
                    return successHandler();
                })
                .catch((err) => errorHandler(err));
        },
    },
    CHANGE_EMAIl: 'change-email',
    CHANGE_PASSWORD: 'change-password',
    RESET_PASSWORD: 'reset-password',
};

export default AUTH_METHODS;
