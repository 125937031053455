import { ALLOWED_BOOKMARKS_COUNT } from '../add';
import { keyify } from '../../../utils/string';
import Bookmark, { BookmarkItem } from '../item';
import React from 'react';

export type BookmarksProps = {
    bookmarks: BookmarkItem[];
    handleDelete: (id: string) => void;
};

const BookmarksList = ({ bookmarks, handleDelete }: BookmarksProps) => {
    return (
        bookmarks &&
        bookmarks
            ?.slice(0, ALLOWED_BOOKMARKS_COUNT)
            ?.map((bookmark: BookmarkItem, index) => {
                return (
                    <Bookmark
                        key={keyify(bookmark.id + '')}
                        item={bookmark}
                        handleDelete={handleDelete}
                        index={index}
                    />
                );
            })
    );
};

export default BookmarksList;
