import { MdAddBox } from 'react-icons/md';
import listStyles from '../index.module.css';
import React from 'react';
import styles from '../item/index.module.css';

export const ALLOWED_BOOKMARKS_COUNT = 10;

export type AddProps = {
    bookmarksLength: number;
    handleClick: () => void;
};

const Add = ({ bookmarksLength, handleClick }: AddProps) => {
    return (
        <div
            className={`${styles.wrapper} ${
                bookmarksLength >= ALLOWED_BOOKMARKS_COUNT ? styles.hide : ''
            } ${listStyles.itemWrapper}`}
            onClick={handleClick}
        >
            <div className={styles.background} />
            <stack-l space='var(--s-2)' class={`${styles.add} ${styles.item}`}>
                <cluster-l
                    space='0'
                    justify='center'
                    align='center'
                    class={styles.icon}
                >
                    <MdAddBox />
                </cluster-l>
                <div className={`${styles.title} ellipsis`}>Add site</div>
            </stack-l>
        </div>
    );
};

export default Add;
