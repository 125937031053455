import React from 'react';
import PropTypes from 'prop-types';

import './settings-switcher.scss';

const SettingsSwitcher = ({ settingKey, label, value, changeSetting }) => {
    const handleChange = (e) => {
        changeSetting({
            key: settingKey,
            value: e.target.checked,
        });
    };

    return (
        <label className='settings-switcher'>
            <span className='settings-switcher__label'>{label}</span>
            <input
                className='settings-switcher__input'
                type='checkbox'
                checked={value}
                onChange={handleChange}
            />
            <span className='settings-switcher__toggler' />
        </label>
    );
};

SettingsSwitcher.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    settingKey: PropTypes.string,
    changeSetting: PropTypes.func,
};

export default SettingsSwitcher;
