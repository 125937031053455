import React from 'react';
import PropTypes from 'prop-types';
import { FAVICON_GETTER_URL } from '../../settings';

import { MdClose } from 'react-icons/md';
import './bookmark-item.scss';

function BookmarkItem({ item, handleDelete }) {
    const bookmarkUrl = item.url.includes('http')
        ? new URL(item.url)
        : new URL(`https://${item.url}`);

    return (
        <a
            className='bookmark-item'
            href={bookmarkUrl}
            target='_blank'
            rel='noreferrer'
        >
            <div className='bookmark-item-icon'>
                <div
                    className='bookmark-item-delete'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleDelete(e, item.id);
                    }}
                >
                    <MdClose />
                </div>
                <img
                    src={`${FAVICON_GETTER_URL}/icon?url=${bookmarkUrl.host}&size=32`}
                />
            </div>
            <h3 className='bookmark-item-title'>{item.title}</h3>
        </a>
    );
}

BookmarkItem.propTypes = {
    item: PropTypes.object.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default BookmarkItem;
