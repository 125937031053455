import './settings-list-fetch.scss';
import { ApiCall } from '../../shared';
import { selectToken, selectUserId } from '../../features/user/userSlice';
import { useSelector } from 'react-redux';
import Card from '../card';
import PropTypes from 'prop-types';
import React, { useState, useCallback, useLayoutEffect } from 'react';
import UpvotePopup from '../Popup';

function SettingsListFetch({ data, upvotes }) {
    const [localUpvotes, setLocalUpvotes] = useState(upvotes);
    const [settingsList, setSettingsList] = useState([]);
    const [isUpvotePopupShown, setIsUpvotePopupShown] = useState(false);
    const [upvotedNGO, setUpvotedNGO] = useState('');
    const userId = useSelector(selectUserId);
    const token = useSelector(selectToken);

    useLayoutEffect(() => {
        (async () => {
            const res = await ApiCall(data?.value, {
                csrf: true,
                token,
            });
            setSettingsList(await res.data);
        })();
    }, []);

    useLayoutEffect(() => {
        (async () => {
            const res = await ApiCall(`auth/get_user_upvotes/${userId}`, {
                csrf: true,
                token,
            });
            setLocalUpvotes(await res.data?.upvotes);
        })();
    }, []);

    const handleUpvote = useCallback(
        (ngo, inputtedUpvotes) => {
            (async () => {
                const user = JSON.parse(localStorage?.getItem('authuser'));
                const res = await ApiCall('auth/ngo/set_upvotes/', {
                    data: JSON.stringify({
                        user_id: user.user_id,
                        ngo_id: ngo.slug,
                        upvotes: inputtedUpvotes,
                    }),
                    token,
                    method: 'post',
                    csrf: true,
                });

                setLocalUpvotes(await res?.data?.user_upvotes);
                setIsUpvotePopupShown(true);
                setUpvotedNGO(ngo.name);
            })();
        },
        [setLocalUpvotes, setIsUpvotePopupShown, setUpvotedNGO],
    );

    const handleUpvotePopupClose = useCallback(() => {
        setIsUpvotePopupShown(false);
    }, [setIsUpvotePopupShown]);

    return (
        <div className='settings-fetched-list'>
            {settingsList && settingsList.length && settingsList !== null
                ? settingsList?.map((listItem) => {
                      const { name, id } = listItem !== null && listItem;
                      return (
                          <Card
                              key={name.toLowerCase().replaceAll(' ', '_') + id}
                              data={listItem}
                              upvotes={localUpvotes}
                              handleUpvote={handleUpvote}
                          />
                      );
                  })
                : ''}

            <UpvotePopup
                open={isUpvotePopupShown}
                handleClose={handleUpvotePopupClose}
                stylesClass='upvote-popup'
            >
                <h2 className='content-title'>Thank you!</h2>
                <p className='content-description'>
                    You've successfully upvoted for {upvotedNGO}
                </p>
            </UpvotePopup>
        </div>
    );
}

SettingsListFetch.propTypes = {
    data: PropTypes.object,
    upvotes: PropTypes.any,
};

export default SettingsListFetch;
