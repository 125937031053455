import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const CustomField = ({
    fieldClass,
    fieldLabelClass,
    fieldErrorLabelClass,
    fieldInputClass,
    fieldLabel,
    searchAction,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <div className={fieldClass}>
            <label className={fieldLabelClass} onClick={searchAction}>
                {typeof fieldLabel === 'string' ? fieldLabel : fieldLabel()}
            </label>
            {meta.touched && meta.error ? (
                <span className={fieldErrorLabelClass}>{meta.error}</span>
            ) : null}
            <input className={fieldInputClass} {...props} {...field} />
        </div>
    );
};

CustomField.propTypes = {
    fieldClass: PropTypes.string,
    fieldLabelClass: PropTypes.string,
    fieldErrorLabelClass: PropTypes.string,
    fieldInputClass: PropTypes.string,
    fieldLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    searchAction: PropTypes.func | undefined,
};

export default CustomField;
