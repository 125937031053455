import React from 'react';
import './logo.scss';
import logo from '../../assets/logo.png';

const Logo = () => {
    return (
        <div className='logo__parent'>
            <img className='logo' src={logo} alt='' />
            <p className='logo__text'>HyeTab</p>
        </div>
    );
};

export default Logo;
