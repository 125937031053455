import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Bookmarker, BookmarkItem } from '../../components';

import { useSelector, useDispatch } from 'react-redux';
import { selectToken, selectUser } from '../../features/user/userSlice';
import {
    getBookmarks,
    setBookmarks,
} from '../../features/bookmarks/bookmarksAPI';

import { MdAddCircle } from 'react-icons/md';

import './bookmarks-widget.scss';

const ALLOWED_BOOKMARKS_COUNT = 12;

function BookmarksWidget({ setting }) {
    const [bookmarksStore, setBookmarksStore] = useState([]);
    const [showBookmarker, setShowBookmarker] = useState(false);
    const [bookmarksShown, setBookmarksShown] = useState(setting?.value);

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    useEffect(() => {
        let sites = [];
        localStorage.removeItem('storageWebsites');

        dispatch(getBookmarks({ id: user.user_id, token })).then(() => {
            if (localStorage) {
                const storage = localStorage.getItem('storageWebsites')
                    ? JSON.parse(localStorage.getItem('storageWebsites'))
                    : [];
                sites = storage;
            }
            sites?.length && setBookmarksStore(sites);
        });
    }, [showBookmarker]);

    useEffect(() => {
        setBookmarksShown(setting.value);
    }, [setting]);

    function setBookmark(bookmark) {
        const bookmarks = JSON.stringify([...bookmarksStore, bookmark]);

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    function handleDelete(e, deleteId) {
        const bookmarks = JSON.stringify(
            bookmarksStore?.filter((item) => {
                return item.id !== deleteId;
            }),
        );

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    return bookmarksShown ? (
        <>
            <div className='bookmarks-widget'>
                <Bookmarker
                    show={showBookmarker}
                    toggleBookmarker={setShowBookmarker}
                    setBookmark={setBookmark}
                />
                <div className='bookmark-items'>
                    {bookmarksStore
                        ?.slice(0, ALLOWED_BOOKMARKS_COUNT)
                        .map((item) => (
                            <BookmarkItem
                                key={item.id}
                                item={item}
                                handleDelete={handleDelete}
                            />
                        ))}
                    <div
                        className={`bookmarker-toggler bookmark-item ${
                            bookmarksStore.length >= ALLOWED_BOOKMARKS_COUNT
                                ? 'hide'
                                : ''
                        }`}
                        onClick={() => setShowBookmarker(!showBookmarker)}
                    >
                        <div className='bookmark-item-icon'>
                            <MdAddCircle />
                        </div>
                    </div>
                </div>
            </div>
        </>
    ) : (
        ''
    );
}

BookmarksWidget.propTypes = {
    setting: PropTypes.array,
};

export default BookmarksWidget;
