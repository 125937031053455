import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import bookmarkSvg from '../../assets/bookmarks.svg';
import Bookmarker from './Bookmarker.js';
import BookmarkItem from './BookmarkItem.js';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, selectUser } from '../../features/user/userSlice';
import {
    getBookmarks,
    setBookmarks,
} from '../../features/bookmarks/bookmarksAPI';

export default function Bookmarks({ setting }) {
    const [bookmarksStore, setBookmarksStore] = useState([]);
    const [showBookmarker, setShowBookmarker] = useState(false);
    const [bookmarksShown, setBookmarksShown] = useState(setting.value);

    const dispatch = useDispatch();
    const user = useSelector(selectUser);
    const token = useSelector(selectToken);

    useEffect(() => {
        let sites = [];
        localStorage.removeItem('storageWebsites');

        dispatch(getBookmarks({ id: user.user_id, token })).then(() => {
            if (localStorage) {
                const storage = localStorage.getItem('storageWebsites')
                    ? JSON.parse(localStorage.getItem('storageWebsites'))
                    : [];
                sites = storage;
            }
            sites?.length && setBookmarksStore(sites);
        });
    }, [showBookmarker]);

    useEffect(() => {
        setBookmarksShown(setting.value);
    }, [setting]);

    function setBookmark(bookmark) {
        const bookmarks = JSON.stringify([bookmark, ...bookmarksStore]);

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    function handleDelete(e, deleteId) {
        const bookmarks = JSON.stringify(
            bookmarksStore?.filter((item) => {
                return item.id !== deleteId;
            }),
        );

        dispatch(setBookmarks({ id: user.user_id, token, bookmarks })).then(
            () => {
                setBookmarksStore(JSON.parse(bookmarks));
            },
        );
    }

    return bookmarksShown ? (
        <>
            <BookmarksWrapper>
                <Bookmarker
                    show={showBookmarker}
                    toggleBookmarker={setShowBookmarker}
                    setBookmark={setBookmark}
                />
                <BookmarksItemWrapper
                    onClick={() => setShowBookmarker(!showBookmarker)}
                >
                    <Img src={bookmarkSvg} alt='bookmarks' />
                </BookmarksItemWrapper>
                {bookmarksStore?.slice(0, 5).map((item) => (
                    <BookmarkItem
                        key={item.id}
                        item={item}
                        handleDelete={handleDelete}
                    />
                ))}
            </BookmarksWrapper>
        </>
    ) : (
        ''
    );
}

Bookmarks.propTypes = {
    setting: PropTypes.array,
};

const BookmarksWrapper = styled.div`
    width: 5vmax;
    position: absolute;
    top: 5%;
    left: 2%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));
    z-index: 2;
`;

const BookmarksItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: center;
`;

const Img = styled.img`
    cursor: pointer;
    margin-bottom: 30px;
    filter: drop-shadow(2px 3px 2px rgba(0, 0, 0, 0.4));
`;
