import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../../components/drawer';
import useDrawer from '../../components/drawer/use-drawer';
import { isNullish } from '../../utils/is-nullish';
import { keyify } from '../../utils/string';
import { selectToken, selectUserId } from '../user/userSlice';
import styles from './index.module.css';
import NotificationItem from './list/item';
import { getNotificationsCount, markAllAsRead } from './_store/effects';
import {
    selectNotifications,
    selectNotificationsCount,
    selectStatus,
    Status,
} from './_store/state';

const Footer: React.FC<{
    handleClick: () => void;
}> = ({ handleClick }) => {
    return (
        <button className={styles.button} onClick={handleClick}>
            Mark all as read
        </button>
    );
};

const NotificationCenter = () => {
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const userId = useSelector(selectUserId);
    const notifications = useSelector(selectNotifications);
    const notificationsCount = useSelector(selectNotificationsCount);

    const status = useSelector(selectStatus);
    const isPending = status === Status.PENDING;
    const { handleClose: onClose, handleOpen, open } = useDrawer();

    const handleClose = useCallback(() => {
        dispatch(getNotificationsCount({ token, userId }));
        onClose();
    }, [dispatch, onClose]);

    const handleClick = useCallback(() => {
        dispatch(markAllAsRead({ token, userId }));
    }, [dispatch]);

    useEffect(() => {
        if (!isNullish(status) && isPending) {
            handleOpen();
        }
    }, [notifications, handleOpen, isPending]);

    return (
        <Drawer
            open={open}
            handleClose={handleClose}
            title="What's New!"
            footer={
                notificationsCount > 0 ? (
                    <Footer handleClick={handleClick} />
                ) : undefined
            }
        >
            {isPending ? (
                <center-l andText class={styles.noContent}>
                    <span>loading...</span>
                </center-l>
            ) : !notifications?.length ? (
                <center-l andText class={styles.noContent}>
                    <span>You're up-to-date!</span>
                </center-l>
            ) : (
                <stack-l space='var(--s1)' class={styles.list}>
                    {notifications?.map((notification) => {
                        return (
                            <NotificationItem
                                key={keyify(notification.item.slug)}
                                data={notification}
                            />
                        );
                    })}
                </stack-l>
            )}
        </Drawer>
    );
};

export default NotificationCenter;
