import axios from 'axios';
import { API_URL, CSRF_TOKEN } from '../settings';

const defaultOptions = {
    data: {},
    token: '',
    method: 'get',
    csrf: true,
};

const ApiCall = async (endpoint, options = defaultOptions) => {
    const { data, token, method, csrf } = options;
    const res = await axios({
        method,
        url: `${API_URL}/${endpoint}`,
        data,
        headers: {
            'Authorization': token && token.length ? 'Token ' + token : '',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': csrf ? CSRF_TOKEN : '',
        },
    });
    return res;
};

function compareObjects(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

export { ApiCall, compareObjects };
