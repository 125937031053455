import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { PrivateRoute } from './routes';
import axios from 'axios';
import {
    AccountSignup,
    SocialSignup,
    NotFoundPage,
    AccountSettings,
} from './containers';

import Newtab from './features/newtab/index.js';

import { API_URL } from './settings';

import { selectUser, selectIsLoggedIn } from './features/user/userSlice';
import { useSelector } from 'react-redux';

function App() {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const location = useLocation();
    const user = useSelector(selectUser);

    useEffect(() => {
        (async () => {
            const res = await axios(`${API_URL}/auth/csrf/`);
            const { token } = await res.data;
            localStorage.setItem('csrf_token', token);
        })();
    }, []);

    useEffect(() => {
        const htmlBody = document.querySelector('body');
        htmlBody.classList.contains('auth') &&
            htmlBody.classList.remove('auth');
        if (isLoggedIn && location.pathname.includes('newtab')) {
            htmlBody.classList.add('auth');
        }

        if (location?.pathname.includes('newtab')) {
            htmlBody.classList.add('newtab');
        } else {
            htmlBody.classList.remove('newtab');
        }
    }, [isLoggedIn, location.pathname]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const staticSiteRoutes = [
        {
            path: '/privacy',
        },
        {
            path: '/terms',
        },
        {
            path: '/team',
        },
        {
            path: '/financials',
        },
        {
            path: '/faq',
        },
        {
            path: '/contact',
        },
    ];

    return (
        <Switch>
            {staticSiteRoutes &&
                staticSiteRoutes.map((route) => {
                    const { path } = route;
                    return (
                        <PrivateRoute
                            absoluteUrl
                            condition={false}
                            exact
                            key={path}
                            path={path}
                            redirectRoute={`https://hyetab.io${path}`}
                        />
                    );
                })}

            <PrivateRoute exact path='/' component={() => <Newtab />} />

            <PrivateRoute
                exact
                path='/login'
                component={({ location }) => (
                    <Redirect
                        to={{
                            pathname: '/newtab',
                            state: { from: location },
                        }}
                    />
                )}
                condition={isLoggedIn}
            />

            <PrivateRoute
                exact
                path='/newtab'
                component={() => <Newtab />}
                condition={isLoggedIn}
            />

            <PrivateRoute
                exact
                path='/account/social'
                component={() => <SocialSignup />}
                redirectRoute='/newtab'
                condition={!isLoggedIn}
            />

            <PrivateRoute
                exact
                path='/account/:auth_method'
                component={() => <AccountSignup />}
                redirectRoute='/newtab'
                condition={!isLoggedIn}
            />

            <PrivateRoute
                exact
                path='/settings/:tab'
                component={() => <AccountSettings user={user} />}
                condition={isLoggedIn}
            />

            <Route path='*' component={NotFoundPage} />
        </Switch>
    );
}

export default App;
