import React, { FC } from 'react';
import { MdClose } from 'react-icons/all';
import styles from './index.module.css';

const CloseButton: FC<{
    handleClose: () => void;
}> = ({ handleClose }) => {
    return (
        <button onClick={handleClose} className={styles.button}>
            <MdClose />
        </button>
    );
};

export default CloseButton;
