import React from 'react';
import { Link } from 'react-router-dom';

import './not-found.scss';

const NotFoundPage = () => {
    return (
        <div className='not-found'>
            <h1 className='not-found__title'>404</h1>

            <p className='not-found__description'>
                Sorry, no page was found {' :( '}
            </p>

            <Link className='not-found__cta' to='/'>
                Go Home
            </Link>
        </div>
    );
};

export default NotFoundPage;
