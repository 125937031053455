import { MdClose } from 'react-icons/all';
import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './index.module.css';

export type PopupProps = PropsWithChildren<{
    handleClose: () => void;
    open: boolean;
    stylesClass?: string;
}>;

const Popup: FunctionComponent<PopupProps> = ({
    handleClose,
    open,
    stylesClass,
    ...props
}: PopupProps) => {
    return (
        <cluster-l
            class={`${styles.popup} ${open ? styles.open : ''}`}
            justify='center'
            align='center'
            space='var(--s5)'
        >
            <div className={styles.overview} onClick={handleClose} />
            <div className={styles.closeBtn} onClick={handleClose}>
                <MdClose />
            </div>
            <stack-l
                class={`${styles.content} ${stylesClass ?? ''}`}
                space='var(--s2)'
            >
                {props.children}
            </stack-l>
        </cluster-l>
    );
};

export default Popup;
