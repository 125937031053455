import { useCallback, useState } from 'react';

const useDrawer = () => {
    const [open, setOpen] = useState(false);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    return {
        open,
        handleClose,
        handleOpen,
    };
};

export default useDrawer;
