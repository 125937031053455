import React, { FC, useCallback, useEffect } from 'react';
import { MdNotifications } from 'react-icons/all';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '../../components';
import { selectToken, selectUserId } from '../user/userSlice';
import styles from './index.module.css';
import { getNotifications, getNotificationsCount } from './_store/effects';
import { selectNotificationsCount } from './_store/state';

const Badge: FC<{ count: number }> = ({ count }) => {
    return count > 0 ? <div className={styles.badge} /> : null;
};

const Trigger = () => {
    const dispatch = useDispatch();
    const notificationsCount = useSelector(selectNotificationsCount);
    const token = useSelector(selectToken);
    const userId = useSelector(selectUserId);

    const handleLoadNotifications = useCallback(() => {
        dispatch(getNotifications({ token, userId }));
    }, [dispatch]);

    useEffect(() => {
        dispatch(getNotificationsCount({ token, userId }));
    }, []);

    return (
        <div className={styles.wrapper}>
            <Tooltip
                content="What's new"
                onClick={handleLoadNotifications}
                badge={<Badge count={notificationsCount} />}
            >
                <span>
                    <MdNotifications />
                </span>
            </Tooltip>
        </div>
    );
};

export default Trigger;
