import { createSlice } from '@reduxjs/toolkit';
import { ApiCall, compareObjects } from '../../shared';
import {
    getMoney,
    googleLogin,
    loginUser,
    logoutUser,
    registerUser,
    setUserTabs,
} from './userAPI';

let token = '';
let authUser = {};
let settings = {};
let isLoggedIn = false;
let counter = 0;
let tabs = 0;

try {
    token = localStorage?.getItem('token');
    authUser = JSON.parse(localStorage.getItem('authuser'));
    settings =
        localStorage?.getItem('settings') &&
        localStorage?.getItem('settings') !== 'undefined' &&
        JSON.parse(localStorage?.getItem('settings'));
    isLoggedIn = JSON.parse(localStorage.getItem('isAuth'));

    counter = localStorage?.getItem('counter');
    tabs = localStorage?.getItem('tabs');
} catch (err) {
    localStorage?.removeItem('token');
    localStorage?.removeItem('authuser');
    localStorage?.removeItem('settings');
    localStorage?.removeItem('isAuth');
    localStorage?.removeItem('counter');
    localStorage?.removeItem('tabs');
}

function setUserData(state, payload) {
    const { user, token, settings } = payload;
    state.isLoggedIn = true;
    state.authUser = user;
    state.token = token;
    state.settings = settings;
    state.counter = counter;
    state.tabs = tabs;

    localStorage.setItem('isAuth', true);
    localStorage.setItem('token', token);
    localStorage.setItem('authuser', JSON.stringify(user));
    localStorage.setItem('settings', JSON.stringify(settings));

    return state;
}

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        authUser,
        settings,
        counter,
        tabs,
        money: 0,
        rewards: 0,
        isLoggedIn,
        token,
    },
    reducers: {
        setStateSettings: (state, action) => {
            const { settings } = action.payload;
            state.settings = settings;
            localStorage.setItem('settings', JSON.stringify(settings));
        },
    },

    extraReducers: {
        [logoutUser.fulfilled]: (state) => {
            state.isLoggedIn = false;
            state.authUser = {};
            state.settings = {};
            state.token = '';
            state.tabs = 0;
            state.money = 0;
            state.counter = 0;

            localStorage.removeItem('isAuth');
            localStorage.removeItem('authuser');
            localStorage.removeItem('token');
            localStorage.removeItem('settings');
        },

        [setUserTabs.fulfilled]: (state, { payload }) => {
            const { tabs, counter, rewards } = payload;
            if (state.tabs !== tabs) {
                state.counter = counter;
                state.tabs = tabs;
                state.rewards = rewards;

                localStorage.setItem('counter', counter);
                localStorage.setItem('tabs', tabs);
            }
        },

        [googleLogin.fulfilled]: (state, { payload }) => {
            setUserData(state, payload);
        },

        [loginUser.fulfilled]: (state, { payload }) => {
            setUserData(state, payload);
        },
        [loginUser.rejected]: (state, { payload }) => {
            console.log(state, payload);
        },

        [registerUser.fulfilled]: (state, { payload }) => {
            setUserData(state, payload);
        },

        [registerUser.rejected]: (state, { payload }) => {
            console.log(state, payload);
        },

        [getMoney.fulfilled]: (state, { payload }) => {
            state.money = payload.adsense_money;
        },
    },
});

export const selectUser = (state) => state.user.authUser;
export const selectToken = (state) => state.user.token;
export const selectTabsCounter = (state) => state.user.tabs;
export const selectUserSettings = (state) => state.user.settings;
export const selectIsLoggedIn = (state) => state.user.isLoggedIn;
export const selectUserRewards = (state) => state.user.rewards;
export const selectMoney = (state) => state.user.money;
export const selectUserId = (state) => state.user.authUser.user_profile_id;

export const { setStateSettings } = userSlice.actions;

export const getSettings = () => (dispatch, getState) => {
    const user = selectUser(getState());
    const token = selectToken(getState());
    const settings = selectUserSettings(getState());
    if (user !== {} && user) {
        ApiCall(`auth/get_settings/${user.user_id}/`, {
            method: 'get',
            csrf: true,
            token,
        }).then((res) => {
            if (!compareObjects(settings, res.data.settings)) {
                dispatch(setStateSettings(res.data));
            }
        });
    }

    return settings;
};

export const setSettings = (settings) => (dispatch) => {
    // const user = selectUser(getState());
    // const settings = selectUserSettings(getState());

    dispatch(setStateSettings(settings));

    // if (user !== {} && user) {
    //   ApiCall('auth/set_user_setting', {
    //     method: 'post',
    //     csrf: true,
    //     data: {
    //       user_id: user.user_id,
    //       setting,
    //     },
    //   }).then((res) => {
    //     if (JSON.stringify(settings) !== JSON.stringify(res.data.settings)) {
    //       dispatch(setStateSettings(res.data));
    //     }
    //   });
    // }
};

export default userSlice.reducer;
