import { createSlice } from '@reduxjs/toolkit';
import { getRandomBackground } from './backgroundGalleryAPI';

export const backgroundSlice = createSlice({
    name: 'background',
    initialState: {
        backgroundItem: {
            img: '',
            img_hash: '',
        },
        artist_info: null,
        charity_info: null,
    },
    reducers: {},

    extraReducers: {
        [getRandomBackground.fulfilled]: (state, { payload }) => {
            state.backgroundItem = payload;
            state.artist_info = {
                ...payload.artist_info,
                location: payload.location,
            };
            state.charity_info = payload.charity_info;
        },
    },
});

export const selectBackgroundItem = (state) => state.background.backgroundItem;
export const selectArtist = (state) => state.background.artist_info;
export const selectCharity = (state) => state.background.charity_info;

export default backgroundSlice.reducer;
