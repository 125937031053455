import './settings-choices.scss';
import { makeLabel } from '../../utils/string';
import PropTypes from 'prop-types';
import React from 'react';

const SettingsChoices = ({
    settingKey,
    label,
    value,
    choices,
    changeSetting,
}) => {
    const handleChange = (e) => {
        changeSetting({
            key: settingKey,
            value: e.target.value,
        });
    };

    return (
        <div className='settings-choices'>
            <span className='settings-choices__label'>{label}</span>
            <div className='choices'>
                {choices && choices.length
                    ? choices.map((choice) => (
                          <label
                              key={`${choice[0]}-choice`}
                              htmlFor={`${choice[0]}-choice`}
                              className='choice-field'
                          >
                              <span className='choice-field__label'>
                                  {makeLabel(choice[0])}
                              </span>
                              <input
                                  id={`${choice[0]}-choice`}
                                  className='choice-field__input'
                                  name={settingKey}
                                  type='radio'
                                  checked={value === choice[0]}
                                  value={choice[0]}
                                  onChange={handleChange}
                              />
                              <span className='choice-field__toggler' />
                          </label>
                      ))
                    : ''}
            </div>
        </div>
    );
};

SettingsChoices.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.number,
    ]),
    choices: PropTypes.array,
    settingKey: PropTypes.string,
    changeSetting: PropTypes.func,
};

export default SettingsChoices;
