import React from 'react';

import './social-signup.scss';
import { SocialSignup as SocialSignupSection } from '../../components';

const SocialSignup = () => {
    return <SocialSignupSection />;
};

export default SocialSignup;
