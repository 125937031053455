import { FAVICON_GETTER_URL } from '../../../settings';
import { MdClose } from 'react-icons/md';
import listStyles from '../index.module.css';
import React, { useCallback } from 'react';
import styles from './index.module.css';

export type BookmarkItem = {
    id: string;
    title: string;
    url: string;
};

export type BookmarkProps = {
    item: BookmarkItem;
    handleDelete: (id: string) => void;
    index: number;
};

const Bookmark = ({ item, handleDelete, index }: BookmarkProps) => {
    const bookmarkUrl = item.url.includes('http')
        ? new URL(item.url)
        : new URL(`https://${item.url}`);

    const onDelete = useCallback(() => {
        handleDelete?.(item.id);
    }, [handleDelete]);

    return (
        <div
            className={`${styles.wrapper} ${listStyles.itemWrapper}`}
            tabIndex={index}
        >
            <span className={styles.close} onClick={onDelete}>
                <MdClose />
            </span>
            <a href={bookmarkUrl + ''} target='_blank' rel='noreferrer'>
                <div className={styles.background} />
                <stack-l space='var(--s-2)' class={styles.item}>
                    <img
                        loading='eager'
                        src={`${FAVICON_GETTER_URL}/icon?url=${bookmarkUrl.host}&size=64`}
                    />
                    <div className={`${styles.title} ellipsis`}>
                        {item?.title}
                    </div>
                </stack-l>
            </a>
        </div>
    );
};

export default Bookmark;
