import React, { memo } from 'react';
import { FiLink } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { isNullish } from '../../utils/is-nullish';
import { selectArtist, selectCharity } from './backgroundGallerySlice';
import styles from './index.module.css';

const ArtistItem = () => {
    const artist = useSelector(selectArtist);
    const charity = useSelector(selectCharity);

    const locationSearchUrl = artist?.location
        ? `https://www.google.com/search?q=${artist.location}`
        : '';

    return !isNullish(charity?.name) || !isNullish(artist?.get_full_name) ? (
        <cluster-l
            align='center'
            class={styles.artist}
            justify='flex-start'
            space='var(--s-1)'
        >
            <FiLink />
            <stack-l space='var(--s-5)'>
                {charity?.donation_url ? (
                    <span>
                        Donate to{' '}
                        <a
                            href={charity?.donation_url}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {charity?.name}
                        </a>
                    </span>
                ) : artist?.get_full_name ? (
                    <span>
                        Photo by{' '}
                        <a
                            href={artist?.website}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {artist?.get_full_name}
                        </a>
                    </span>
                ) : null}
                <span>
                    {charity?.about_url ? (
                        <>
                            About:{' '}
                            <a
                                href={charity?.about_url}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {charity?.about_url}
                            </a>
                        </>
                    ) : locationSearchUrl ? (
                        <a
                            href={locationSearchUrl}
                            target='_blank'
                            rel='noreferrer'
                        >
                            {artist.location}
                        </a>
                    ) : (
                        artist.location
                    )}
                </span>
            </stack-l>
        </cluster-l>
    ) : null;
};

export default memo(ArtistItem);
