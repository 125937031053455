import { IoTriangle } from 'react-icons/io5';
import { isNullish } from '../../utils/is-nullish';
import DescriptionPopup from '../Popup';
import React, { useCallback, useState } from 'react';
import styles from './index.module.scss';
import parse from 'html-react-parser';

export interface NgoData {
    category?: string | null;
    creation_date?: string | null;
    short_description?: string | null;
    long_description?: string | null;
    email?: string | null;
    id?: number | null;
    img?: string | null;
    name?: string | null;
    reward_points?: number | null;
    slug?: string | null;
    website?: string;
}

export interface CardProps {
    data: NgoData;
    upvotes: number;
    handleUpvote?: (ngo: NgoData, upvotes: number) => void;
}

const ERRORS = {
    NO_UPVOTES: "You don't have any available upvotes",
    GREATER_THAN_UPVOTES: (upvotes?: number) =>
        `Please enter an amount no bigger than your available upvotes: ${upvotes}`,
};

const Card = ({ data, upvotes, handleUpvote }: CardProps) => {
    const {
        name,
        img,
        short_description: description,
        long_description: richDescription,
        website,
    } = data;
    const [inputtedUpvotes, setInputtedUpvotes] = useState<number>(0);
    const [inputError, setInputError] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const handleClick = useCallback(() => {
        if (upvotes === 0) {
            setInputError(ERRORS.NO_UPVOTES);
            return;
        }

        if (inputtedUpvotes > upvotes) return;

        if (isNullish(upvotes)) return;
        if (isNullish(inputtedUpvotes)) return;

        handleUpvote?.(data, inputtedUpvotes > 0 ? inputtedUpvotes : upvotes);
        setInputtedUpvotes(0);
    }, [inputtedUpvotes, upvotes, setInputError, setInputtedUpvotes]);

    const handleChange = useCallback(
        (e: { target: { value: string } }) => {
            if (Number(e.target.value) > Number(upvotes)) {
                if (upvotes === 0) {
                    setInputError(ERRORS.NO_UPVOTES);
                } else {
                    setInputError(ERRORS.GREATER_THAN_UPVOTES(upvotes));
                }
                return;
            }
            setInputtedUpvotes(Number(e.target.value));
            setInputError('');
        },
        [setInputError, setInputtedUpvotes],
    );

    const handleWebsiteClick = useCallback(() => {
        if (!isNullish(window)) {
            if (!isNullish(website) && website !== '') {
                window?.open?.(website, '_blank')?.focus();
            }
        }
    }, [website]);

    const handlePopup = useCallback(
        (open?: boolean) => {
            setShowPopup(open ?? false);
        },
        [setShowPopup],
    );

    const isUpvoteDisabled =
        inputtedUpvotes > upvotes ?? upvotes === 0 ?? isNullish(upvotes);

    return (
        <>
            <div className={styles.card}>
                <div className={styles.body}>
                    {img && (
                        <img
                            className={`${styles.avatar} ${
                                website && styles.pointer
                            }`}
                            srcSet={img}
                            onClick={handleWebsiteClick}
                        />
                    )}
                    <div className={styles.texts}>
                        <h2
                            className={`${styles.name} ${
                                website && styles.pointer
                            }`}
                            onClick={handleWebsiteClick}
                        >
                            {name}
                        </h2>

                        {description && (
                            <div
                                className={`${styles.description} ${
                                    showPopup && styles.shown
                                }`}
                            >
                                <p>{description.substring(0, 100) + '...'}</p>

                                <button
                                    className={styles.moreBtn}
                                    onClick={() => handlePopup(true)}
                                >
                                    {showPopup ? 'Less' : 'More'}
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.footer}>
                    <div className={styles.field}>
                        <input
                            className={`${styles.input} ${
                                inputtedUpvotes > 0 ? styles.valid : ''
                            }`}
                            type='number'
                            max={String(upvotes)}
                            placeholder='Enter a custom amount'
                            onChange={handleChange}
                        />
                        <span
                            className={`${styles.error} ${
                                inputError !== '' ? styles.shown : ''
                            }`}
                        >
                            {inputError}
                        </span>
                    </div>

                    <button
                        className={styles.upvote}
                        disabled={isUpvoteDisabled}
                        onClick={handleClick}
                    >
                        Upvote
                        <span className={styles.rewards}>
                            {(inputtedUpvotes > 0 && inputtedUpvotes) ||
                                upvotes}
                        </span>
                        <span className={styles.icon}>
                            <IoTriangle />
                        </span>
                    </button>
                </div>
            </div>

            {showPopup && (
                <DescriptionPopup
                    handleClose={() => handlePopup(false)}
                    open={showPopup}
                    stylesClass={styles.popup}
                >
                    <h2>{name}</h2>
                    <stack-l recursive space='0'>
                        {richDescription && parse(richDescription)}
                    </stack-l>
                </DescriptionPopup>
            )}
        </>
    );
};

export default Card;
