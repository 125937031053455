import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

const CustomInput = ({
    inputWrapper: Wrapper,
    inputLabelWrapper: InputLabel,
    errorLabel: ErrorLabel,
    inputElement: Input,
    label,
    ...props
}) => {
    const [field, meta] = useField(props);
    return (
        <Wrapper>
            <InputLabel>{label}</InputLabel>
            {meta.touched && meta.error ? (
                <ErrorLabel>{meta.error}</ErrorLabel>
            ) : null}
            <Input {...props} {...field} />
        </Wrapper>
    );
};

CustomInput.propTypes = {
    inputWrapper: PropTypes.object,
    inputLabelWrapper: PropTypes.object,
    errorLabel: PropTypes.object,
    label: PropTypes.string,
    inputElement: PropTypes.object,
};

export default CustomInput;
