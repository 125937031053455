import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import { Formik, Form } from 'formik';
import CustomInput from '../CustomInput';
import * as Yup from 'yup';

// disable no-useless-escape
const BookmarkerValidation = Yup.object({
    bookmark__title: Yup.string().required('Title is Required'),
    bookmark__url: Yup.string()
        .matches(
            // eslint-disable-next-line
            /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
            'Enter correct url',
        )
        .required('Url is Required'),
});

const colors = {
    valid: '#4ede91',
    error: '#bb5320',
    outline: '#3D7D99',
};

export default function Bookmarker({ show, toggleBookmarker, setBookmark }) {
    const BookmarkerCloseBtn = styled.button`
        position: absolute;
        top: 5%;
        right: 5%;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        background: white;
        color: #292929;
        border: #292929;
        cursor: pointer;
    `;
    const BookmarkerBackground = styled.div`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
    `;

    const BookmarkerWrapper = styled.div`
        display: ${show ? 'block' : 'none'};
        width: 100vw;
        height: 100vh;
        background-color: rgba(127, 127, 127, 0.5);
        position: absolute;
        margin: 0px;
        padding: 0px;
        top: 0;
        left: 0;
        z-index: 2;
    `;
    const BookmarkerForm = styled.div`
        display: grid;
        place-items: center;
        grid-row-gap: 2.5em;
        background: white;
        color: black;
        width: 30%;
        min-height: 20vmax;
        padding: 5em;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
    `;

    const BookmarkInput = styled.label`
        display: grid;
        align-items: center;
        grid-row-gap: 0.5em;
        position: relative;
        width: 100%;
    `;

    const BookmarkInputType = styled.input`
        padding: 0.5em 0.2em;
        outline: none;
        font-size: max(0.8rem, min(1.2vw, 1.2rem));
        border: 1px solid ${colors.outline};
        border-bottom: 4px solid ${colors.outline};
        transition: all 0.5s ease-in-out;
        color: black;
        opacity: 1;
        transition: all 0.3s ease;

        &:active,
        &:focus {
            border-color: transparent;
            border-bottom-color: ${colors.outline};
        }

        &::placeholder {
            font-size: calc(100% - 2px);
            color: black;
            opacity: 0.8;
        }
    `;

    const BookmarkInputLabel = styled.div`
        text-align: left;
        font-size: max(1rem, min(1.5vw, 1.5rem));
        color: black;
    `;

    const BookmarkErrorLabel = styled.div`
        text-align: left;
        color: ${colors.error};
        position: absolute;
        top: 100%;
        bottom: -30%;
        margin-top: 0.2em;
        font-size: max(0.7rem, min(1.1vw, 1.1rem));

        & ~ ${BookmarkInputType} {
            color: ${colors.error};
            border-color: ${colors.error};
            opacity: 1;
        }
    `;

    const BookmarkerBtnWrapper = styled.div`
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        align-items: flex-end;

        & button {
            outline: none;
            border: none;
            color: white;
            cursor: pointer;
            font-size: max(0.6rem, min(1vw, 1rem));
            padding: 0.5em 1em;
            text-transform: capitalize;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
            opacity: 0.8;
            transition: opacity 0.3s ease;

            &:hover,
            &:active,
            &:focus {
                opacity: 1;
            }
        }
    `;

    const SaveBtn = styled.button`
        background-color: ${colors.outline};
    `;

    const CancelBtn = styled.button`
        background-color: grey;
    `;

    function hideBookmarkerWithEsc(e) {
        e.keyCode === 27 && toggleBookmarker(false);
    }

    useEffect(() => {
        window.addEventListener('keyup', hideBookmarkerWithEsc);
        return () => window.removeEventListener('keyup', hideBookmarkerWithEsc);
    }, []);

    return (
        <BookmarkerWrapper>
            <BookmarkerBackground onClick={() => toggleBookmarker(false)} />
            <BookmarkerCloseBtn onClick={() => toggleBookmarker(false)}>
                <MdClose />
            </BookmarkerCloseBtn>

            <Formik
                initialValues={{
                    bookmark__url: '',
                    bookmark__title: '',
                }}
                validationSchema={BookmarkerValidation}
                validateOnChange
                onSubmit={(e) => {
                    console.log(e);
                    setBookmark({
                        id: `${Date.now()}-${e.bookmark__url}`,
                        url: e.bookmark__url,
                        title: e.bookmark__title,
                    });

                    toggleBookmarker(false);
                }}
            >
                {({ values, submitForm, resetForm }) => (
                    <Form>
                        <BookmarkerForm>
                            <CustomInput
                                name='bookmark__title'
                                id='bookmark__title'
                                inputElement={BookmarkInputType}
                                inputLabelWrapper={BookmarkInputLabel}
                                label='Title'
                                placeholder="Enter website's name"
                                inputWrapper={BookmarkInput}
                                errorLabel={BookmarkErrorLabel}
                                value={values.title}
                            />

                            <CustomInput
                                name='bookmark__url'
                                id='bookmark__url'
                                type='url'
                                inputElement={BookmarkInputType}
                                inputLabelWrapper={BookmarkInputLabel}
                                label='Link'
                                placeholder="Enter website's link"
                                inputWrapper={BookmarkInput}
                                errorLabel={BookmarkErrorLabel}
                                value={values.url}
                            />

                            <BookmarkerBtnWrapper>
                                <SaveBtn
                                    onClick={(e) => {
                                        e.preventDefault();
                                        submitForm();
                                    }}
                                >
                                    save
                                </SaveBtn>
                                <CancelBtn
                                    onClick={(e) => {
                                        e.preventDefault();
                                        resetForm();
                                        toggleBookmarker(false);
                                    }}
                                >
                                    cancel
                                </CancelBtn>
                            </BookmarkerBtnWrapper>
                        </BookmarkerForm>
                    </Form>
                )}
            </Formik>
        </BookmarkerWrapper>
    );
}

Bookmarker.propTypes = {
    show: PropTypes.bool,
    toggleBookmarker: PropTypes.func,
    setBookmark: PropTypes.func,
};
