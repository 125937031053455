const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000/api';
const BACKEND_BASE =
    process.env.REACT_APP_BACKEND_BASE || 'http://localhost:8000';

const ALBUM_ID = process.env.REACT_APP_GOOGLE_ALBUM_ID || 'AirkmHJfvdNgSDTHA';
const CSRF_TOKEN = localStorage.getItem('csrf_token');
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const FAVICON_GETTER_URL = process.env.REACT_APP_FAVICON_GETTER_URL;

export {
    ALBUM_ID,
    API_URL,
    BACKEND_BASE,
    CSRF_TOKEN,
    FAVICON_GETTER_URL,
    GOOGLE_CLIENT_ID,
};
