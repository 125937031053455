import React, { FC, PropsWithChildren } from 'react';
import CloseButton from '../close-button';
import styles from './index.module.css';

const Drawer: FC<PropsWithChildren<{
    open: boolean;
    handleClose: () => void;
    title: string;
    extraAction?: React.ReactNode;
    footer?: React.ReactNode;
}>> = ({ open, handleClose, title, extraAction, footer, children }) => {
    return (
        <div className={`${styles.wrapper} ${open ? styles.open : ''}`}>
            <div className={styles.background} onClick={handleClose} />

            <aside className={styles.content}>
                <stack-l splitAfter={3} space='var(--s-2)'>
                    <cluster-l
                        align='center'
                        class={styles.header}
                        justify='space-between'
                        space='var(--s-2)'
                    >
                        <h2 className={styles.title}>{title}</h2>
                        <CloseButton handleClose={handleClose} />
                    </cluster-l>
                    <div className={styles.body}>
                        {extraAction}
                        {children}
                    </div>
                    <cluster-l
                        class={styles.footer}
                        space='var(--s-2)'
                        justify='flex-end'
                    >
                        {footer}
                    </cluster-l>
                </stack-l>
            </aside>
        </div>
    );
};

export default Drawer;
