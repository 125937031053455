import styled from 'styled-components';

const colors = {
    valid: '#4ede91',
    error: '#bb5320',
    outline: '#3D7D99',
};

const AuthBtn = styled.button`
    display: flex;
    align-items: center;
    background-color: white;
    box-shadow: 1px 2px 5px 1px rgba(0, 0, 0, 0.4);
    border: none;
    padding: 1em 1.5em;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1vw;

    svg {
        margin-right: 0.2em;
        font-size: 2vw;
    }
`;

const AuthSection = styled.div`
    background-color: #3d7d99;
    height: 100%;
    width: 50%;
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-columns: 100%;
`;

const AuthWrapper = styled.div`
    width: 60%;
    margin: auto;
`;

const AuthTitle = styled.h1`
    color: white;
    text-align: center;
    font-size: max(2rem, min(3.5vw, 3rem));
`;

const AuthForm = styled.div`
    display: grid;
    place-items: center;
    grid-row-gap: 2.5em;
    color: white;
    position: relative;
`;

const AuthInput = styled.label`
    display: grid;
    align-items: center;
    grid-row-gap: 0.5em;
    position: relative;
    width: 100%;
`;

const AuthInputType = styled.input`
    padding: 1em 1.5em;
    outline: none;
    font-size: max(0.8rem, min(1.2vw, 1.2rem));
    border: 1px solid ${colors.outline};
    border-bottom: 4px solid ${colors.outline};
    transition: all 0.5s ease-in-out;
    color: black;
    opacity: 1;
    transition: all 0.3s ease;

    &:active,
    &:focus {
        border-color: transparent;
        border-bottom-color: ${colors.outline};
    }

    &::placeholder {
        font-size: calc(100% - 2px);
        color: black;
        opacity: 0.8;
    }
`;

const AuthInputLabel = styled.div`
    text-align: left;
    font-size: max(1rem, min(1.5vw, 1.5rem));
    color: white;
`;

const AuthErrorLabel = styled.div`
    text-align: left;
    color: ${colors.error};
    position: absolute;
    top: 100%;
    bottom: -30%;
    margin-top: 0.2em;
    font-size: max(0.7rem, min(1.1vw, 1.1rem));

    & ~ ${AuthInputType} {
        color: ${colors.error};
        border-color: ${colors.error};
        opacity: 1;
    }
`;

const AuthResponseStatus = styled.div`
    text-align: left;
    color: ${colors.error};
    position: absolute;
    top: 57%;
    bottom: -30%;
    margin-top: 0.2em;
    font-size: max(0.7rem, min(1.1vw, 1.1rem));

    & ~ ${AuthInputType} {
        color: ${colors.error};
        border-color: ${colors.error};
        opacity: 1;
    }
`;

const AuthBtnWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    & button {
        width: 100%;
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
        font-size: max(0.6rem, min(1vw, 1rem));
        padding: 1em 1.5em;
        text-transform: capitalize;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
        opacity: 0.8;
        transition: opacity 0.3s ease;

        &:hover,
        &:active,
        &:focus {
            opacity: 1;
        }
    }
`;

const RegisterBtn = styled.button`
    background-color: ${colors.valid};
`;

const AuthText = styled.p`
    color: white;
    font-size: max(0.6rem, min(1vw, 1rem));
`;

const AuthHighlighted = styled.span`
    color: ${colors.valid};
    cursor: pointer;

    a {
        color: inherit;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const SocialHighlighted = styled.span`
    display: block;
    margin: 1em 0;
    cursor: pointer;
    a {
        color: ${colors.valid};
    }
`;

export {
    AuthBtn,
    AuthSection,
    AuthWrapper,
    AuthTitle,
    AuthForm,
    AuthInput,
    AuthInputType,
    AuthInputLabel,
    AuthErrorLabel,
    AuthResponseStatus,
    AuthBtnWrapper,
    RegisterBtn,
    AuthText,
    AuthHighlighted,
    SocialHighlighted,
};
