import { makeLabel } from '../../../utils/string';
import React from 'react';

export type LayoutChoicesProps = {
    settingKey: string;
    label: string;
    value: string;
    choices: string[];
    changeSetting: (setting: { key: string; value?: string }) => void;
};

const LayoutChoices = ({
    settingKey,
    label,
    value,
    choices,
    changeSetting,
}: LayoutChoicesProps) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        changeSetting({
            key: settingKey,
            value: e?.target?.value,
        });
    };

    return (
        <cluster-l justify='flex-start'>
            <stack-l class='settings-choices'>
                <div className='choices'>
                    {choices && choices.length
                        ? choices.map((choice) => (
                              <label
                                  key={`${choice[0]}-choice`}
                                  htmlFor={`${choice[0]}-choice`}
                                  className='choice-field'
                              >
                                  <span className='choice-field__label'>
                                      {makeLabel(choice[0])}
                                  </span>
                                  <input
                                      id={`${choice[0]}-choice`}
                                      className='choice-field__input'
                                      name={settingKey}
                                      type='radio'
                                      checked={value === choice[0]}
                                      value={choice[0]}
                                      onChange={handleChange}
                                  />
                                  <span className='choice-field__toggler' />
                              </label>
                          ))
                        : ''}
                </div>
            </stack-l>
        </cluster-l>
    );
};

export default LayoutChoices;
