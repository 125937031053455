import React from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { useLocation } from 'react-router';

import DEFAULT_META from './default_meta';

function MetaTags({ title, description, img, keywords }) {
    const location = useLocation();
    const url = 'https://hyetab.io';
    const fullUrl = `${url}${location.pathname}${location.search}`;

    const defaultTitle = 'HyeTab - a Tab to Donate to Armenia';
    const defaultDescription =
        'Raise money to donate to Armenia with every tab you open in Your browser!';

    return (
        <Helmet
            encodeSpecialCharacters
            defaultTitle={defaultTitle}
            titleTemplate='%s'
        >
            <html lang='en' />
            <meta
                name='viewport'
                content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
            />

            {title && <title itemProp='name'>{title || defaultTitle}</title>}
            <link rel='canonical' href={url} />
            <meta name='og:site_name' content={DEFAULT_META.siteName} />
            <meta property='og:url' content={fullUrl} />
            <meta property='og:type' content='website' />

            <meta name='og:title' content={title || defaultTitle} />
            <meta property='og:title' content={title || defaultTitle} />
            <meta
                name='og:description'
                content={description || defaultDescription}
            />
            <meta
                name='description'
                content={description || defaultDescription}
            />

            <meta name='og:image' content={img || DEFAULT_META.img} />
            <meta property='og:image:type' content='image/jpeg' />
            <meta property='og:image:width' content='400' />
            <meta property='og:image:height' content='300' />

            <meta name='twitter:card' content='summary' />
            <meta name='twitter:url' content={fullUrl} />
            <meta name='twitter:title' content={title || defaultTitle} />
            <meta
                name='twitter:description'
                content={description || defaultDescription}
            />
            <meta name='twitter:image' content={img || DEFAULT_META.img} />
            <meta name='twitter:image:alt' content={title || defaultTitle} />

            <link rel='canonical' href={url} />

            {keywords && <meta name='keywords' content={keywords} />}

            {/* ----------------------------------Favicon---------------------------------- */}
            {DEFAULT_META.favicon && DEFAULT_META.favicon.icon && (
                <link
                    rel='shortcut icon'
                    sizes='16x16 24x24 32x32 48x48 64x64'
                    href={`/media/favicon/${DEFAULT_META.favicon.icon}`}
                />
            )}

            {/* --------------------------------Web-------------------------------- */}
            {DEFAULT_META.favicon &&
                DEFAULT_META.favicon.web &&
                Object.keys(DEFAULT_META.favicon.web).map(
                    (keyName, i) =>
                        DEFAULT_META.favicon.web[keyName] && (
                            <link
                                key={i}
                                rel='icon'
                                type='image/png'
                                sizes={keyName}
                                href={`/media/favicon/${DEFAULT_META.favicon.web[keyName]}`}
                            />
                        ),
                )}
            {/* --------------------------------Web-------------------------------- */}

            {/* --------------------------------IOS-------------------------------- */}
            {DEFAULT_META.favicon &&
                DEFAULT_META.favicon.ios &&
                Object.keys(DEFAULT_META.favicon.ios).map(
                    (keyName, i) =>
                        DEFAULT_META.favicon.ios[keyName] && (
                            <link
                                key={i}
                                rel='apple-touch-icon'
                                href={`/media/favicon/${DEFAULT_META.favicon.ios[keyName]}`}
                                sizes={keyName}
                            />
                        ),
                )}
            <meta name='theme-color' content='#ffffff' />
            <meta content='yes' name='apple-mobile-web-app-capable' />
            <meta
                name='apple-mobile-web-app-status-bar-style'
                content='black-translucent'
            />
            {/* --------------------------------IOS-------------------------------- */}

            {/* --------------------------------Android-------------------------------- */}
            {DEFAULT_META.favicon &&
                DEFAULT_META.favicon.android &&
                Object.keys(DEFAULT_META.favicon.android).map(
                    (keyName, i) =>
                        DEFAULT_META.favicon.android[keyName] && (
                            <link
                                key={i}
                                rel='icon'
                                type='image/png'
                                href={`/media/favicon/${DEFAULT_META.favicon.android[keyName]}`}
                                sizes={keyName}
                            />
                        ),
                )}
            {/* --------------------------------Android-------------------------------- */}

            {/* ----------------------------------Favicon---------------------------------- */}

            {/* --------------------------------IOS-------------------------------- */}
            {DEFAULT_META.app &&
                DEFAULT_META.app.ios &&
                Object.keys(DEFAULT_META.app.ios).map(
                    (keyName, i) =>
                        DEFAULT_META.app.android[keyName] && (
                            <meta
                                key={i}
                                property={`al:ios:${keyName}`}
                                content={DEFAULT_META.app.ios[keyName]}
                            />
                        ),
                )}
            {/* --------------------------------IOS-------------------------------- */}

            {/* --------------------------------Android-------------------------------- */}
            {DEFAULT_META.app &&
                DEFAULT_META.app.android &&
                Object.keys(DEFAULT_META.app.android).map(
                    (keyName, i) =>
                        DEFAULT_META.app.android[keyName] && (
                            <meta
                                key={i}
                                property={`al:android:${keyName}`}
                                content={DEFAULT_META.app.android[keyName]}
                            />
                        ),
                )}
            <meta property='al:web:url' content={fullUrl} />
            {/* --------------------------------Android-------------------------------- */}

            {/* --------------------------------Twitter-------------------------------- */}
            {DEFAULT_META.social &&
                DEFAULT_META.social.twitter &&
                Object.keys(DEFAULT_META.social.twitter).map(
                    (keyName, i) =>
                        DEFAULT_META.social.twitter[keyName] && (
                            <meta
                                key={i}
                                property={`twitter:${keyName}`}
                                content={DEFAULT_META.social.twitter[keyName]}
                            />
                        ),
                )}
            <meta name='twitter:url' content={fullUrl} />
            <meta name='twitter:title' content={title || defaultTitle} />
            <meta
                name='twitter:description'
                content={description || defaultDescription}
            />
            <meta name='twitter:image' content={img || DEFAULT_META.img} />
            {/* --------------------------------Twitter-------------------------------- */}
        </Helmet>
    );
}

MetaTags.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    img: PropTypes.string,
    keywords: PropTypes.string,
};

export default MetaTags;
