import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../../shared';
import { NotificationDetails } from './state';

type HasToken = {
    token: string;
};
type HasUserId = {
    userId: number;
};

export type HasNotificationId = {
    notificationId: number;
};

type payload = HasToken & HasUserId;

const getNotificationsCount = createAsyncThunk(
    'notifications/getCount',
    async ({
        token,
        userId,
    }: payload): Promise<{
        count: number;
    }> => {
        const response = await ApiCall(
            `auth/notifications/get_unread_notifications/${userId}/`,
            {
                data: {},
                token,
                method: 'get',
                csrf: true,
            },
        );

        return response.data;
    },
);

const getNotifications = createAsyncThunk(
    'notifications/getNotifications',
    async ({ token, userId }: payload): Promise<NotificationDetails[]> => {
        const response = await ApiCall(`auth/notifications/list/${userId}/`, {
            data: {},
            method: 'get',
            csrf: true,
            token,
        });

        return response.data;
    },
);

const markAllAsRead = createAsyncThunk(
    'notifications/markAllAsRead',
    async ({ token, userId }: payload) => {
        const response = await ApiCall('auth/notifications/mark_all_as_read/', {
            data: {
                user_id: userId,
            },
            method: 'post',
            csrf: true,
            token,
        });

        return response.data;
    },
);

const markAsRead = createAsyncThunk(
    'notifications/markAsRead',
    async ({ token, notificationId, userId }: payload & HasNotificationId) => {
        const response = await ApiCall('auth/notifications/mark_as_read/', {
            data: {
                notification_id: notificationId,
                user_id: userId,
            },
            method: 'post',
            csrf: true,
            token,
        });

        return response.data;
    },
);

export { getNotifications, getNotificationsCount, markAllAsRead, markAsRead };
