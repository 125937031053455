import { AccountAuthForm } from '../../components';
import React from 'react';
import styled from 'styled-components';

const AccountSignup = () => {
    return (
        <Layout>
            <AccountAuthForm />
        </Layout>
    );
};

const Layout = styled.div`
    background-color: #3d7d99;
    display: grid;
    grid-template-columns: 100%;
    justify-items: center;
    height: 100vh;
    z-index: 100;
`;

export default AccountSignup;
