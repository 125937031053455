import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { Formik, Form } from 'formik';
import { CustomField } from '../../components';

import * as Yup from 'yup';

import './bookmarker.scss';

// disable no-useless-escape
const BookmarkerValidation = Yup.object({
    bookmark__title: Yup.string().required('Title is Required'),
    bookmark__url: Yup.string()
        .matches(
            // eslint-disable-next-line
            /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
            'Enter correct url',
        )
        .required('Url is Required'),
});

export default function Bookmarker({ show, toggleBookmarker, setBookmark }) {
    function hideBookmarkerWithEsc(e) {
        e.keyCode === 27 && toggleBookmarker(false);
    }

    useEffect(() => {
        window.addEventListener('keyup', hideBookmarkerWithEsc);
        return () => window.removeEventListener('keyup', hideBookmarkerWithEsc);
    }, []);

    return (
        <div className={`bookmarker ${show ? 'show' : ''}`}>
            <div
                className='bookmarker-bg'
                onClick={() => toggleBookmarker(false)}
            />
            <div
                className='bookmarker-close-btn'
                onClick={() => toggleBookmarker(false)}
            >
                <MdClose />
            </div>

            <Formik
                initialValues={{
                    bookmark__url: '',
                    bookmark__title: '',
                }}
                validationSchema={BookmarkerValidation}
                validateOnChange
                onSubmit={(e, { resetForm }) => {
                    console.log(e);
                    setBookmark({
                        id: `${Date.now()}-${e.bookmark__url}`,
                        url: e.bookmark__url,
                        title: e.bookmark__title,
                    });

                    resetForm();
                    toggleBookmarker(false);
                }}
            >
                {({ values, submitForm, resetForm }) => (
                    <Form className='bookmarker-form'>
                        <CustomField
                            name='bookmark__title'
                            id='bookmark__title'
                            fieldClass='bookmarker-form__field'
                            fieldLabelClass='field__label'
                            fieldErrorLabelClass='field__error'
                            fieldInputClass='field__input'
                            fieldLabel='Title'
                            placeholder="Enter website's name"
                            value={values.title}
                        />

                        <CustomField
                            name='bookmark__url'
                            id='bookmark__url'
                            type='url'
                            fieldClass='bookmarker-form__field'
                            fieldLabelClass='field__label'
                            fieldErrorLabelClass='field__error'
                            fieldInputClass='field__input'
                            fieldLabel='Link'
                            placeholder="Enter website's link"
                            value={values.url}
                        />

                        <div className='bookmarker-actions-wrapper'>
                            <button
                                className='action-btn save'
                                onClick={(e) => {
                                    e.preventDefault();
                                    submitForm();
                                }}
                            >
                                save
                            </button>
                            <button
                                className='action-btn cancel'
                                onClick={(e) => {
                                    e.preventDefault();
                                    resetForm();
                                    toggleBookmarker(false);
                                }}
                            >
                                cancel
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

Bookmarker.propTypes = {
    show: PropTypes.bool,
    toggleBookmarker: PropTypes.func,
    setBookmark: PropTypes.func,
};
