/* eslint-disable react/self-closing-comp */
import React, { useEffect, useRef } from 'react';
import styles from './index.module.css';

const AdsWidget = () => {
    const squareAdRef = useRef<HTMLDivElement>(null);
    const horizontalAdRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src =
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3656049597554087';
        script.crossOrigin = 'anonymous';
        squareAdRef.current?.appendChild(script);

        const ins = document.createElement('ins');
        ins.classList.add('adsbygoogle');
        ins.classList.add(styles.square);
        ins.setAttribute(
            'style',
            'display:inline-block;width:150px;height:150px;background:white',
        );
        ins.setAttribute('data-ad-client', 'ca-pub-3656049597554087');
        ins.setAttribute('data-ad-slot', '5594343199');
        squareAdRef.current?.appendChild(ins);

        const script2 = document.createElement('script');
        script2.append('(adsbygoogle = window.adsbygoogle || []).push({});');
        squareAdRef.current?.appendChild(script2);
        return () => {
            squareAdRef.current?.removeChild(script);
            squareAdRef.current?.removeChild(ins);
            squareAdRef.current?.removeChild(script2);
        };
    }, []);

    useEffect(() => {
        const script = document.createElement('script');
        script.async = true;
        script.src =
            'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3656049597554087';
        script.crossOrigin = 'anonymous';
        horizontalAdRef.current?.appendChild(script);

        const ins = document.createElement('ins');
        ins.classList.add('adsbygoogle');
        ins.classList.add(styles.horizontal);
        ins.setAttribute(
            'style',
            'display:inline-block;width:728px;height:90px;background:white',
        );
        ins.setAttribute('data-ad-client', 'ca-pub-3656049597554087');
        ins.setAttribute('data-ad-slot', '1599257864');
        horizontalAdRef.current?.appendChild(ins);

        const script2 = document.createElement('script');
        script2.append('(adsbygoogle = window.adsbygoogle || []).push({});');
        horizontalAdRef.current?.appendChild(script2);

        return () => {
            horizontalAdRef.current?.removeChild(script);
            horizontalAdRef.current?.removeChild(ins);
            horizontalAdRef.current?.removeChild(script2);
        };
    }, []);

    return (
        <stack-l space='var(--s0)' class={styles.adsWrapper}>
            <cluster-l
                justify='flex-end'
                class={`${styles.ad}`}
                ref={squareAdRef}
            />
            <cluster-l
                justify='flex-end'
                class={`${styles.ad}`}
                ref={horizontalAdRef}
            />
        </stack-l>
    );
};

export default AdsWidget;
