import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import searchIcon from '../assets/search.svg';

const SearchInput = () => {
    const [input, setInput] = useState('');
    const [barOpened, setBarOpened] = useState(false);
    const formRef = useRef();
    const inputFocus = useRef();

    useEffect(() => {
        console.log(window.navigator);
    }, []);

    const onFormSubmit = (e) => {
        e.preventDefault();
        window.location.replace(`https://www.google.com/search?q=${input}`);
        setInput('');
        setBarOpened(false);
    };

    return (
        <SearchWrapper>
            <Form
                barOpened={barOpened}
                onClick={() => {
                    setBarOpened(true);
                    inputFocus.current.focus();
                }}
                onFocus={() => {
                    setBarOpened(true);
                    inputFocus.current.focus();
                }}
                onBlur={() => {
                    setBarOpened(false);
                }}
                onSubmit={onFormSubmit}
                ref={formRef}
            >
                <Input
                    onChange={(e) => setInput(e.target.value)}
                    ref={inputFocus}
                    value={input}
                    barOpened={barOpened}
                    placeholder='Search...'
                />
                <Button type='submit' barOpened={barOpened}>
                    <img src={searchIcon} alt='search input' />
                </Button>
            </Form>
        </SearchWrapper>
    );
};

const SearchWrapper = styled.div`
    margin-left: 2.2em;
    position: absolute;
    left: 7%;
    top: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Form = styled.form`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${(props) => (props.barOpened ? '16.25rem' : '2rem')};
    cursor: ${(props) => (props.barOpened ? 'auto' : 'pointer')};
    height: 2rem;
    transition: width 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const Input = styled.input`
    font-size: 1rem;
    line-height: 1;
    background-color: white;
    width: 80%;
    height: 2rem;
    padding: 0.2em 1em;
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4));

    border: none;
    border-radius: 36px;
    color: black;
    cursor: ${(props) => (props.barOpened ? 'auto' : 'pointer')};
    opacity: ${(props) => (props.barOpened ? '0.5' : '0')};
    transition: margin 300ms cubic-bezier(0.645, 0.045, 0.355, 1);

    &:focus,
    &:active {
        outline: none;
    }
    &::placeholder {
        color: white;
    }
`;

const Button = styled.button`
    line-height: 1;
    pointer-events: ${(props) => (props.barOpened ? 'auto' : 'none')};
    cursor: ${(props) => (props.barOpened ? 'pointer' : 'pointer')};
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    position: absolute;
    right: ${(props) => (props.barOpened ? '20px' : '0')};
    filter: drop-shadow(2px 3px 3px rgba(0, 0, 0, 0.4)); ;
`;

export default SearchInput;
