const useDate = (date, locale = 'en-US') => {
    const d = new Date(date);
    const armWeekdays = [
        'Կիրակի',
        'Երկուշաբթի',
        'Երեքշաբթի',
        'Չորեքշաբթի',
        'Հինգշաբթի',
        'Ուրբաթ',
        'Շաբաթ',
    ];
    const armMonths = [
        'Հունվար',
        'Փետրվար',
        'Մարտ',
        'Ապրիլ',
        'Մայիս',
        'Հունիս',
        'Հուլիս',
        'Օգոստոս',
        'Սեպտեմբեր',
        'Հոկտեմբեր',
        'Նոյեմբեր',
        'Դեկտեմբեր',
    ];
    const weekday = d.getDay();
    const monthId = d.getMonth();

    const condition = locale === 'hy-AM' || locale === 'hy' || locale === 'am';

    return {
        weekDay: condition
            ? armWeekdays[weekday]
            : d.toLocaleString(locale, { weekday: 'long' }),
        day: String(d.getDate()).length < 2 ? `0${d.getDate()}` : d.getDate(),
        month: condition
            ? armMonths[monthId]
            : d.toLocaleString(locale, { month: 'long' }),

        year: d.getFullYear(),
        hours: d.getHours(),
        minutes: d.getMinutes(),
        seconds: d.getSeconds(),
    };
};

export default useDate;
