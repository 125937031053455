import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../shared';

const logoutUser = createAsyncThunk('user/logoutUser', async () => {
    const res = await ApiCall('auth/logout/', {
        data: {},
        method: 'post',
        csrf: true,
    });

    return res.data;
});

const googleLogin = createAsyncThunk('user/googleLogin', async (profile) => {
    const res = await ApiCall('auth/google/login/', {
        data: {
            profile,
        },
        method: 'post',
        csrf: true,
    });
    return res.data;
});

const loginUser = createAsyncThunk('user/loginUser', async (data) => {
    const { user__email, user__password } = data;

    const res = await ApiCall('auth/login/', {
        data: {
            email: user__email,
            password: user__password,
        },
        method: 'post',
        csrf: true,
    });
    return res.data;
});

const registerUser = createAsyncThunk('user/registerUser', async (data) => {
    const { user__name, user__email, user__password } = data;
    const res = await ApiCall('auth/register/', {
        data: {
            username: user__name,
            email: user__email,
            password: user__password,
        },
        method: 'post',
        csrf: true,
    });
    return res.data;
});

const setUserTabs = createAsyncThunk('user/setTabs', async (data) => {
    const { username, id, token } = data;
    const res = await ApiCall('auth/set_tabs/', {
        data: {
            username,
            id,
        },
        token,
        method: 'post',
        csrf: true,
    });
    return res.data;
});

const getMoney = createAsyncThunk('user/getMoney', async ({ token }) => {
    const res = await ApiCall('auth/get_adsense_money/', {
        method: 'get',
        csrf: true,
        token,
    });

    return res.data;
});

export {
    getMoney,
    googleLogin,
    loginUser,
    logoutUser,
    registerUser,
    setUserTabs,
};
