import * as Yup from 'yup';

const RegisterFormValidation = Yup.object({
    user__name: Yup.string().required('Username is Required'),
    user__email: Yup.string()
        .email('Must be a valid email address')
        .required('Email is Required'),
    user__password: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});

const LoginFormValidation = Yup.object({
    user__email: Yup.string()
        .email('Must be a valid email address')
        .required('Email is Required'),
    user__password: Yup.string()
        .required('Password is required')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
});

export { LoginFormValidation, RegisterFormValidation };
