import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiCall } from '../../shared';

const getBookmarks = createAsyncThunk(
    'bookmarks/getBookmarks',
    async (data) => {
        const { id, token } = data;
        const res = await ApiCall(`auth/get_bookmarks/${id}/`, {
            method: 'get',
            csrf: true,
            token,
        });

        return res.data;
    },
);

const setBookmarks = createAsyncThunk(
    'bookmarks/setBookmarks',
    async (data) => {
        const { id: user_id, bookmarks, token } = data;
        const res = await ApiCall('auth/set_bookmarks/', {
            data: {
                user_id,
                bookmarks,
            },
            token,
            method: 'post',
            csrf: true,
        });
        return res.data;
    },
);

export { getBookmarks, setBookmarks };
