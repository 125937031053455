import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
    SettingInput,
    SettingsChoices,
    SettingsListFetch,
    SettingsSwitcher,
    SettingsTabs,
    StatisticBlock,
} from '../../components';
import {
    getSettings,
    selectTabsCounter,
    selectToken,
} from '../../features/user/userSlice';
import { ApiCall } from '../../shared';
import { makeLabel } from '../../utils/string';
import './account-settings.scss';
import Comment from './Comment';
import LayoutChoices from './layout-choices';

const AccountSettings = ({ user }) => {
    const [allSettings, setAllSettings] = useState({});
    const [settings, setSettings] = useState({});
    const [tab, setTab] = useState('');
    const location = useLocation();
    const dispatch = useDispatch();
    const token = useSelector(selectToken);
    const upvotes = useSelector(selectTabsCounter);

    useEffect(() => {
        const pathArray = location.pathname.split('/');
        const tab = pathArray[pathArray.length - 1];

        const val = dispatch(getSettings());
        changeTab(tab, val);
        setTab(tab);
        setAllSettings(val);
    }, [location.pathname]);

    const changeSetting = (setting) => {
        (async () => {
            const res = await ApiCall('auth/set_user_setting/', {
                method: 'post',
                csrf: true,
                data: {
                    user_id: user.user_id,
                    setting,
                },
                token,
            });

            const { settings } = await res.data;

            if (JSON.stringify(allSettings) !== JSON.stringify.settings) {
                const settingsTab = settings[tab];
                setSettings(settingsTab);
                setAllSettings(settings);
            }
        })();
    };

    const changeTab = (tab, settings) => {
        (async () => {
            setSettings({});
            const settingsTab = settings[tab];
            setSettings(settingsTab);
        })();
    };

    return (
        <div className='settings'>
            <SettingsTabs />
            {settings && settings !== {} && Object.keys(settings).length && (
                <div className='settings__content'>
                    {Object.keys(settings).map((settingGroup) => {
                        return (
                            <div
                                className='settings__content__group'
                                key={`${settingGroup}-group`}
                            >
                                <h2 className='group__title'>
                                    {(() => {
                                        const settingsGroupTitle = makeLabel(
                                            settingGroup,
                                        );

                                        return settingsGroupTitle ===
                                            'non profit organizations' ? (
                                            <Comment />
                                        ) : (
                                            settingsGroupTitle
                                        );
                                    })()}
                                </h2>
                                {settings &&
                                    settings !== {} &&
                                    settings[settingGroup] &&
                                    settings[settingGroup].length &&
                                    settings[settingGroup].map((setting) => {
                                        switch (setting?.value_type) {
                                            case 'checkbox':
                                                return (
                                                    <SettingsSwitcher
                                                        key={`${settingGroup}-${setting.key}`}
                                                        settingKey={setting.key}
                                                        label={setting?.label}
                                                        value={setting?.value}
                                                        changeSetting={
                                                            changeSetting
                                                        }
                                                    />
                                                );
                                            case 'radio':
                                                if (
                                                    setting.key === 'tab_layout'
                                                ) {
                                                    return (
                                                        <LayoutChoices
                                                            key={`${settingGroup}-${setting.key}`}
                                                            settingKey={
                                                                setting.key
                                                            }
                                                            label={
                                                                setting?.label
                                                            }
                                                            value={
                                                                setting?.value
                                                            }
                                                            choices={
                                                                setting?.choices
                                                            }
                                                            changeSetting={
                                                                changeSetting
                                                            }
                                                        />
                                                    );
                                                }

                                                return (
                                                    <SettingsChoices
                                                        key={`${settingGroup}-${setting.key}`}
                                                        settingKey={setting.key}
                                                        label={setting?.label}
                                                        value={setting?.value}
                                                        choices={
                                                            setting?.choices
                                                        }
                                                        changeSetting={
                                                            changeSetting
                                                        }
                                                    />
                                                );
                                            case 'input':
                                                return (
                                                    <SettingInput
                                                        setting={setting}
                                                        key={`${settingGroup}-${setting.key}`}
                                                    />
                                                );
                                            case 'api-fetch':
                                                return (
                                                    <SettingsListFetch
                                                        data={setting}
                                                        upvotes={upvotes}
                                                        key={`${settingGroup}-${setting.key}`}
                                                    />
                                                );
                                            default:
                                                return (
                                                    <StatisticBlock
                                                        setting={setting}
                                                        key={`${settingGroup}-${setting.key}`}
                                                    />
                                                );
                                        }
                                    })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

AccountSettings.propTypes = {
    user: PropTypes.object.isRequired,
};

export default AccountSettings;
